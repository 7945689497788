/**
*
* Eléments réutilisables :
* boutons, icônes, popin (lightbox, notification…), slider (styles communs)…
*
**/
$montserrat : 'Montserrat', cursive;
.montserrat {font-family: $montserrat !important;}
.titre-etoiles {
	margin-top: 1rem;
	position: relative;
    font-size: 1.75rem;
	&:before {
	   content: "\e931";
	   font-family: 'icomoon';
	   position: absolute;
	   top:-1.2rem;
	   opacity: .3;
	   font-size: .9rem;
	}
}
.titre-feuille {
	position: relative;
	padding-left: 2.7rem;
	&:before {
		content: "\e92d";
		font-family: 'icomoon';
		position: absolute;
		left:0;
		opacity: .2;
		font-size: 2.5rem;
	}
}
.no-link {
	pointer-events: none;
	cursor: default;
}
body{
	min-height:100vh; /* 1 */
	display:flex; /* 2 */
	flex-direction:column; /* 3 */
}
.main{
	flex-grow:1; /* 4 */
	position: relative;
	z-index: 1;
}
body.sphere {
	@include media-breakpoint-up(md) {
		.content {
			position: relative;
			&:before {
				content: "\e913";
				font-family: icomoon;
				font-size: 35rem;
				left: -15rem;
				top:0;
				position: absolute;
				color: $olive;
				opacity: .2;
			}
			&:after {
				content: "\e913";
				font-family: icomoon;
				font-size: 35rem;
				right: -15rem;
				bottom:10%;
				position: absolute;
				color: $orange;
				opacity: .2;
			}
		}
	}
}
/* Titres */
.header_page-title {
	font-weight: bold;
	text-align: center;
	position: absolute;
	bottom: 7rem;
	color: #FFF;
	text-align: center;
	display: inline-block;
	width: 100%;
	margin-bottom: 0.5rem;
	line-height: 1.2rem;
	font-size: 2.5rem;
	@include media-breakpoint-up(md) {
		&:before {
			content: "\e931";
			font-family: 'icomoon';
			position: absolute;
			font-size: 1rem;
			top: -2rem;
			left: 50%;
			margin-left: -22px;
		}
	}
	@include media-breakpoint-down(md) {
		line-height: inherit;
	}
	&:hover {
		cursor: default;
	}
}
.table td {
	border: 0 !important;
}
/* Titres couleurs */
body.page-defaut, body.actualites {
	h1, h2, h3 {
		color: $ushuaiablue;
	}
}
body.piscine_et_loisirs {
	h1, h2, h3 {
		color: $ushuaiablue;
	}
}

/* on évite les débordements */
img{max-width: 100%;}
.map{
    max-width: 100%;
     overflow: hidden;
    > div {
        max-width: 100%;
        overflow: hidden;
    }
}
.breadcrumb {
	padding-left: 0;
	margin-top: 1rem;
	&-item {
		display: flex;
		a {
			color : #000;
			font-size: .9rem;
		}
	}
}

body.noshebergements .breadcrumb-item:nth-child(2) {
	display: none;
}

/* boutons */
.btn-primary {
    background-color: $ushuaiablue;
    border-color: $ushuaiablue;
	color: $white;
    &:hover {
        background: darken($ushuaiablue, 10%);
    }
}
.btn-prev{
    &:before{
        content: '\e603';
        @extend .icomoon;
        padding-right: 0.5rem;
        vertical-align: -2px;
    }
}
.btn-next{
    &:after{
        content: '\e602';
        @extend .icomoon;
        padding-left: 0.5rem;
        vertical-align: -2px;
    }
}
.btn-back{
    &:before{
        content: '\e609';
        @extend .icomoon;
        padding-right: 0.5rem;
        vertical-align: -2px;
    }
}
.btn-dispo {
	background: $ushuaiagold;
	display: inline-block;
	padding: 0.5rem 2rem;
	border-radius: 2rem;
	text-align: center;
	color:#FFF;
	transition: .3s;
	&:hover {
		text-decoration: none;
		color:#FFF;
		background: lighten($ushuaiagold, 5);
	}
}

/* galerie d'images*/
.article-gallery{
    .lightbox{
        overflow: hidden;
        max-height: 12.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
        //création d'un colonnage pour les éléments de galerie
        @include make-col-ready();
        @include media-breakpoint-up(sm) {
           @include make-col(6);
        }
        @include media-breakpoint-up(md) {
           @include make-col(4);
        }
        @include media-breakpoint-up(lg) {
           @include make-col(3);
        }
    }
}

/* pagination*/
.pager_current_page{
    @extend .active;
}


/*===================================
=            Alert Oldie            =
===================================*/

.oldie {
	.alert-ie {
		border-top:10px solid #e44204;
		& > div {
			padding: 20px 70px;
			background: url(/images/alert-ie.png) left center no-repeat;
		}
		strong { color:#e44204; }
	}
}

/*=====  End of Alert Oldie  ======*/


/*==================================
=            Skip links            =
==================================*/

.skip-nav {
	position: absolute;
	padding: 0;
	list-style: none;
	z-index:9999;
	a {
		position: absolute;
		left: -7000px;
		padding: 0.5em;
		background: $gray-800;
		color: white;
		text-decoration: none;
		&:focus{
			position: static;
		}
	}
}

/*=====  End of Skip links  ======*/


/*==========================================
=            Cookies validation            =
==========================================*/

.alert-cookie {
	display: none;
	transition:none;
	overflow: hidden;
	text-align: center;
	background-color: $body-color;
	background-color: rgba(0,0,0,0.85);
	color:#fff;
	animation: cookie-anim 1s ease-in;
	padding: 10px;
	margin: 0;

	&.is-hidden{
		animation: none;
		max-height: 0;
		padding:0;
		margin: 0;
		transition: all 0.5s ease-out;
	}

	&.fixed-bottom{
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
	}
}
@keyframes cookie-anim {
	0% { max-height: 0; padding:0; margin:0;}
	100% { max-height: 70px; padding: 10px; }
}
.alert-cookie--link, .alert-cookie--link:visited {
	display: inline-block;
	color: #fff;
	&:hover, &:focus{
		color: #fff;
	}
}
// .alert-cookie--btn {
// 	/* Retrouver les class .btn et .btn-primary directement dans le fichier /zephyr/cookie.js */
// }

/*=====  End of Cookies validation  ======*/

body.mobil_homes_bardage_bois {
	.box__hebergement-list {
		.box__hebergement-item {
			&:first-child {
				display: none;
			}
		}
	}
}
body.mobil_home {
	.box__hebergement-list {
		.box__hebergement-item {
			&:nth-child(2) {
				display: none;
			}
		}
	}
}
body.logements_insolites {
	.box__hebergement-list {
		.box__hebergement-item {
			&:nth-child(3) {
				display: none;
			}
		}
	}
}
body.emplacement_de_camping {
	.box__hebergement-list {
		.box__hebergement-item {
			&:nth-child(4) {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	body.page-home {
		.table td {
			vertical-align: middle;

			img {
				max-width: initial;
			}
		}
	}
}
