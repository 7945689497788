/*	--------------------------------------------------
	--------------------------------------------------
		gestion des comptes, et ses widgets
	----------------------------------------------
	Fichier : account (/account/login/box_login.html)
--------------------------------------------------
--------------------------------------------------*/

// .box-login {}
// .box-login--form {}
// .box-login--pwd {}
// .box-login--subscribe {}
// .box-login--connect {}
// .box-login--user {}
.box-login--logout {
    i{vertical-align: -2px;}
}
.box-login--manage {
    i{vertical-align: -2px;}
}

.account-action--item{
	margin-bottom: 30px;
}

.account-action--link{
	display: block;
	text-align: center;
	padding: 20px;
	border:1px solid $gray-400;
}

/** RESPONSIVE **/

// Small devices (landscape phones, 34em and up)
// @include media-breakpoint-up(sm) {

// }

// Medium devices (tablets, 48em and up)
// @include media-breakpoint-up(md) {

// }

// Large devices (desktops, 62em and up)
// @include media-breakpoint-up(lg) {

// }

// Extra large devices (large desktops, 75em and up)
// @include media-breakpoint-up(xl) {

// }
