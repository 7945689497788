@charset "UTF-8";
/*	--------------------------------------------------
	--------------------------------------------------

	Project			: xxx
	Creation date	: xx/xxx
	By				: xxx/xx

	--------------------------------------------------
	--------------------------------------------------*/
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import "./jquery.fancybox.min.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap&family=Poppins:wght@400;700&display=swap");
:root {
  --blue: #4f77aa;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #cb4f24;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --ushuaiablue: #1b2a48;
  --ushuaiagold: #b7944c;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #b7944c;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 820px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1b2a48;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1b2a48;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #060a10;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.65rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 820px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 820px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ebe1cd;
}

.table-hover .table-primary:hover {
  background-color: #e4d6bb;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #e4d6bb;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 819.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #F0F0F0;
  background-clip: padding-box;
  border: 0 solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #F0F0F0;
  border-color: #dccaa7;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(183, 148, 76, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 0);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #F0F0F0;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 0);
  padding-bottom: calc(0.375rem + 0);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 0);
  padding-bottom: calc(0.5rem + 0);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 0);
  padding-bottom: calc(0.25rem + 0);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0;
}
.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 0);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 0);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .form-group.error .form-control,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .form-group.error .form-control:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-group.error .form-control ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.form-group.error .form-control ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .form-group.error .form-check-input.form-control ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-group.error .form-check-input.form-control ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.form-group.error .form-check-input.form-control ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, .form-group.error .custom-control-input.form-control ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, .form-group.error .custom-control-input.form-control ~ .custom-control-label::before {
  background-color: #efa2a9;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .form-group.error .custom-control-input.form-control ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.form-group.error .custom-control-input.form-control ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .form-group.error .custom-control-input.form-control:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .form-group.error .custom-control-input.form-control:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, .form-group.error .custom-file-input.form-control ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before, .form-group.error .custom-file-input.form-control ~ .custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .form-group.error .custom-file-input.form-control ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.form-group.error .custom-file-input.form-control ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, .form-group.error .custom-file-input.form-control:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover, .btn:focus {
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(183, 148, 76, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .btn.pager_current_page:not(:disabled):not(.disabled) {
  background-image: none;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #212529;
  background-color: #b7944c;
  border-color: #b7944c;
}
.btn-primary:hover {
  color: #fff;
  background-color: #9d7f3f;
  border-color: #94773c;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 148, 76, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #212529;
  background-color: #b7944c;
  border-color: #b7944c;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary.pager_current_page:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #94773c;
  border-color: #8b7038;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 148, 76, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary.pager_current_page:not(:disabled):not(.disabled), .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .btn-success.pager_current_page:not(:disabled):not(.disabled), .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .btn-success.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info.pager_current_page:not(:disabled):not(.disabled), .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .btn-info.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .btn-warning.pager_current_page:not(:disabled):not(.disabled), .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .btn-danger.pager_current_page:not(:disabled):not(.disabled), .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .btn-light.pager_current_page:not(:disabled):not(.disabled), .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .btn-dark.pager_current_page:not(:disabled):not(.disabled), .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #b7944c;
  background-color: transparent;
  background-image: none;
  border-color: #b7944c;
}
.btn-outline-primary:hover {
  color: #212529;
  background-color: #b7944c;
  border-color: #b7944c;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 148, 76, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #b7944c;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary.pager_current_page:not(:disabled):not(.disabled), .show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #b7944c;
  border-color: #b7944c;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 148, 76, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary.pager_current_page:not(:disabled):not(.disabled), .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success.pager_current_page:not(:disabled):not(.disabled), .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info.pager_current_page:not(:disabled):not(.disabled), .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning.pager_current_page:not(:disabled):not(.disabled), .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger.pager_current_page:not(:disabled):not(.disabled), .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light.pager_current_page:not(:disabled):not(.disabled), .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark.pager_current_page:not(:disabled):not(.disabled), .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark.pager_current_page:not(:disabled):not(.disabled):focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1b2a48;
  background-color: transparent;
}
.btn-link:hover {
  color: #060a10;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}
.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #1b2a48;
  text-align: left;
  list-style: none;
  background-color: #1b2a48;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item.pager_current_page, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #b7944c;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group > .btn.pager_current_page,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn.pager_current_page {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: 0;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: 0;
}

.input-group-prepend {
  margin-right: 0;
}

.input-group-append {
  margin-left: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0 solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #b7944c;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(183, 148, 76, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #eae0cb;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #b7944c;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #b7944c;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(183, 148, 76, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(183, 148, 76, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #b7944c;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(183, 148, 76, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 0);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #dccaa7;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(220, 202, 167, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #F0F0F0;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 0);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 0);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 0);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 0);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-control {
  border-color: #dccaa7;
  box-shadow: 0 0 0 0.2rem rgba(183, 148, 76, 0.25);
}
.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #dccaa7;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 0);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #F0F0F0;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 0) - 1px * 2);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-link.pager_current_page,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active, .nav-pills .nav-link.pager_current_page,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #b7944c;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active, .tab-content > .pager_current_page {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 819.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 820px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .pager_current_page > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.pager_current_page {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .pager_current_page > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.pager_current_page {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-top img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
.card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
.card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
.card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
.card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
.card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
.card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
.card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "";
  font-family: "icomoon";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active, .breadcrumb-item.pager_current_page {
  color: #8f9126;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1b2a48;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  color: #060a10;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(183, 148, 76, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link, .page-item.pager_current_page .page-link {
  z-index: 1;
  color: #fff;
  background-color: #b7944c;
  border-color: #b7944c;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge, .errorForm, .error-form {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty, .errorForm:empty, .error-form:empty {
  display: none;
}

.btn .badge, .btn .errorForm, .btn .error-form {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #212529;
  background-color: #b7944c;
}
.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #94773c;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger, .errorForm, .error-form {
  color: #fff;
  background-color: #dc3545;
}
.badge-danger[href]:hover, [href].errorForm:hover, [href].error-form:hover, .badge-danger[href]:focus, [href].errorForm:focus, [href].error-form:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert, .confirmation_subscription .confirmation_validation, .errorMessage, .correct {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #5f4d28;
  background-color: #f1eadb;
  border-color: #ebe1cd;
}
.alert-primary hr {
  border-top-color: #e4d6bb;
}
.alert-primary .alert-link {
  color: #3b3019;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success, .confirmation_subscription .confirmation_validation, .correct {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr, .confirmation_subscription .confirmation_validation hr, .correct hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link, .confirmation_subscription .confirmation_validation .alert-link, .correct .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger, .errorMessage {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr, .errorMessage hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link, .errorMessage .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #b7944c;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #1b2a48;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}
.list-group-item.active, .list-group-item.pager_current_page {
  z-index: 2;
  color: #fff;
  background-color: #b7944c;
  border-color: #b7944c;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #5f4d28;
  background-color: #ebe1cd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #5f4d28;
  background-color: #e4d6bb;
}
.list-group-item-primary.list-group-item-action.active, .list-group-item-primary.list-group-item-action.pager_current_page {
  color: #fff;
  background-color: #5f4d28;
  border-color: #5f4d28;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active, .list-group-item-secondary.list-group-item-action.pager_current_page {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active, .list-group-item-success.list-group-item-action.pager_current_page {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active, .list-group-item-info.list-group-item-action.pager_current_page {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active, .list-group-item-warning.list-group-item-action.pager_current_page {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active, .list-group-item-danger.list-group-item-action.pager_current_page {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active, .list-group-item-light.list-group-item-action.pager_current_page {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active, .list-group-item-dark.list-group-item-action.pager_current_page {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  color: #333;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow, .bs-popover-auto[x-placement^=top] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow, .bs-popover-auto[x-placement^=right] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^=bottom] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow, .bs-popover-auto[x-placement^=left] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #1b2a48;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item.active, .carousel-item.pager_current_page,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right,
.carousel-item-right.pager_current_page {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
.active.carousel-item-right,
.carousel-item-right.pager_current_page {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left,
.carousel-item-left.pager_current_page {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
.active.carousel-item-left,
.carousel-item-left.pager_current_page {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active, .carousel-indicators .pager_current_page {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #b7944c !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #94773c !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #b7944c !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 820px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 820px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 820px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 820px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 820px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #b7944c !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #94773c !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge, .errorForm, .error-form {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #ddd !important;
  }
}
/* Modules d'E-majine */
/*	--------------------------------------------------
	--------------------------------------------------
		Agencement des blocs structurants :
	container, header, content, sidebar, footer
--------------------------------------------------
--------------------------------------------------*/
body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.header {
  position: relative;
}
.header .bg-header {
  position: absolute;
  top: 0;
  background: url("/media/r_foret.jpg");
  background-size: cover;
  height: 400px;
  width: 100%;
}
.header .bg-header img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.header .container {
  position: relative;
  min-height: 400px;
}
@media (min-width: 820px) {
  .header:after {
    content: url("/images/bgdefaut2.svg");
    position: absolute;
    top: 310px;
    left: 50%;
    margin-left: -1192px;
  }
}

.page-defaut .logo.navbar-brand {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.4));
  text-align: center;
  width: 100%;
  filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.4));
}
.page-defaut .logo.navbar-brand img {
  width: 177px;
}
@media (min-width: 820px) {
  .page-defaut .logo.navbar-brand {
    text-align: inherit;
    top: 3rem;
  }
  .page-defaut .logo.navbar-brand img {
    width: 277px;
  }
}

@media (min-width: 820px) {
  .page-home .header {
    min-height: 600px;
  }
}
.page-home .logo.navbar-brand {
  position: absolute;
  display: block;
  top: 20%;
  z-index: 5;
  -webkit-filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 7px rgba(0, 0, 0, 0.7));
}
@media (min-width: 820px) {
  .page-home .logo.navbar-brand {
    left: 50%;
    top: 20%;
    margin-left: -288px;
  }
}
@media (max-width: 991.98px) {
  .page-home .logo.navbar-brand {
    top: 7%;
  }
}
.page-home .logo.navbar-brand img {
  width: 576px;
}

.content {
  padding-bottom: 4rem;
}

.main img {
  border-radius: 10px;
}

.footer {
  background: #1b2a48;
  color: #FFF;
  padding-top: 4rem;
  padding-bottom: 1rem;
}
.footer a {
  color: #b7944c;
}

/**
*
* Eléments réutilisables :
* boutons, icônes, popin (lightbox, notification…), slider (styles communs)…
*
**/
.montserrat {
  font-family: "Montserrat", cursive !important;
}

.titre-etoiles {
  margin-top: 1rem;
  position: relative;
  font-size: 1.75rem;
}
.titre-etoiles:before {
  content: "";
  font-family: "icomoon";
  position: absolute;
  top: -1.2rem;
  opacity: 0.3;
  font-size: 0.9rem;
}

.titre-feuille {
  position: relative;
  padding-left: 2.7rem;
}
.titre-feuille:before {
  content: "";
  font-family: "icomoon";
  position: absolute;
  left: 0;
  opacity: 0.2;
  font-size: 2.5rem;
}

.no-link {
  pointer-events: none;
  cursor: default;
}

body {
  min-height: 100vh;
  /* 1 */
  display: flex;
  /* 2 */
  flex-direction: column;
  /* 3 */
}

.main {
  flex-grow: 1;
  /* 4 */
  position: relative;
  z-index: 1;
}

@media (min-width: 820px) {
  body.sphere .content {
    position: relative;
  }
  body.sphere .content:before {
    content: "";
    font-family: icomoon;
    font-size: 35rem;
    left: -15rem;
    top: 0;
    position: absolute;
    color: #8f9126;
    opacity: 0.2;
  }
  body.sphere .content:after {
    content: "";
    font-family: icomoon;
    font-size: 35rem;
    right: -15rem;
    bottom: 10%;
    position: absolute;
    color: #cb4f24;
    opacity: 0.2;
  }
}

/* Titres */
.header_page-title {
  font-weight: bold;
  text-align: center;
  position: absolute;
  bottom: 7rem;
  color: #FFF;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-bottom: 0.5rem;
  line-height: 1.2rem;
  font-size: 2.5rem;
}
@media (min-width: 820px) {
  .header_page-title:before {
    content: "";
    font-family: "icomoon";
    position: absolute;
    font-size: 1rem;
    top: -2rem;
    left: 50%;
    margin-left: -22px;
  }
}
@media (max-width: 991.98px) {
  .header_page-title {
    line-height: inherit;
  }
}
.header_page-title:hover {
  cursor: default;
}

.table td {
  border: 0 !important;
}

/* Titres couleurs */
body.page-defaut h1, body.page-defaut h2, body.page-defaut h3, body.actualites h1, body.actualites h2, body.actualites h3 {
  color: #1b2a48;
}

body.piscine_et_loisirs h1, body.piscine_et_loisirs h2, body.piscine_et_loisirs h3 {
  color: #1b2a48;
}

/* on évite les débordements */
img {
  max-width: 100%;
}

.map {
  max-width: 100%;
  overflow: hidden;
}
.map > div {
  max-width: 100%;
  overflow: hidden;
}

.breadcrumb {
  padding-left: 0;
  margin-top: 1rem;
}
.breadcrumb-item {
  display: flex;
}
.breadcrumb-item a {
  color: #000;
  font-size: 0.9rem;
}

body.noshebergements .breadcrumb-item:nth-child(2) {
  display: none;
}

/* boutons */
.btn-primary {
  background-color: #1b2a48;
  border-color: #1b2a48;
  color: #fff;
}
.btn-primary:hover {
  background: #0d1423;
}

.btn-prev:before {
  content: "";
  padding-right: 0.5rem;
  vertical-align: -2px;
}

.btn-next:after {
  content: "";
  padding-left: 0.5rem;
  vertical-align: -2px;
}

.btn-back:before {
  content: "";
  padding-right: 0.5rem;
  vertical-align: -2px;
}

.btn-dispo {
  background: #b7944c;
  display: inline-block;
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  text-align: center;
  color: #FFF;
  transition: 0.3s;
}
.btn-dispo:hover {
  text-decoration: none;
  color: #FFF;
  background: #be9f5e;
}

/* galerie d'images*/
.article-gallery .lightbox {
  overflow: hidden;
  max-height: 12.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 576px) {
  .article-gallery .lightbox {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 820px) {
  .article-gallery .lightbox {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (min-width: 992px) {
  .article-gallery .lightbox {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

/* pagination*/
/*===================================
=            Alert Oldie            =
===================================*/
.oldie .alert-ie {
  border-top: 10px solid #e44204;
}
.oldie .alert-ie > div {
  padding: 20px 70px;
  background: url(/images/alert-ie.png) left center no-repeat;
}
.oldie .alert-ie strong {
  color: #e44204;
}

/*=====  End of Alert Oldie  ======*/
/*==================================
=            Skip links            =
==================================*/
.skip-nav {
  position: absolute;
  padding: 0;
  list-style: none;
  z-index: 9999;
}
.skip-nav a {
  position: absolute;
  left: -7000px;
  padding: 0.5em;
  background: #343a40;
  color: white;
  text-decoration: none;
}
.skip-nav a:focus {
  position: static;
}

/*=====  End of Skip links  ======*/
/*==========================================
=            Cookies validation            =
==========================================*/
.alert-cookie {
  display: none;
  transition: none;
  overflow: hidden;
  text-align: center;
  background-color: #1b2a48;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  animation: cookie-anim 1s ease-in;
  padding: 10px;
  margin: 0;
}
.alert-cookie.is-hidden {
  animation: none;
  max-height: 0;
  padding: 0;
  margin: 0;
  transition: all 0.5s ease-out;
}
.alert-cookie.fixed-bottom {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

@keyframes cookie-anim {
  0% {
    max-height: 0;
    padding: 0;
    margin: 0;
  }
  100% {
    max-height: 70px;
    padding: 10px;
  }
}
.alert-cookie--link, .alert-cookie--link:visited {
  display: inline-block;
  color: #fff;
}
.alert-cookie--link:hover, .alert-cookie--link:focus, .alert-cookie--link:visited:hover, .alert-cookie--link:visited:focus {
  color: #fff;
}

/*=====  End of Cookies validation  ======*/
body.mobil_homes_bardage_bois .box__hebergement-list .box__hebergement-item:first-child {
  display: none;
}

body.mobil_home .box__hebergement-list .box__hebergement-item:nth-child(2) {
  display: none;
}

body.logements_insolites .box__hebergement-list .box__hebergement-item:nth-child(3) {
  display: none;
}

body.emplacement_de_camping .box__hebergement-list .box__hebergement-item:nth-child(4) {
  display: none;
}

@media screen and (max-width: 600px) {
  body.page-home .table td {
    vertical-align: middle;
  }
  body.page-home .table td img {
    max-width: initial;
  }
}
/*	--------------------------------------------------
	--------------------------------------------------
		gestion des comptes, et ses widgets
	----------------------------------------------
	Fichier : account (/account/login/box_login.html)
--------------------------------------------------
--------------------------------------------------*/
.box-login--logout i {
  vertical-align: -2px;
}

.box-login--manage i {
  vertical-align: -2px;
}

.account-action--item {
  margin-bottom: 30px;
}

.account-action--link {
  display: block;
  text-align: center;
  padding: 20px;
  border: 1px solid #ced4da;
}

/** RESPONSIVE **/
/*	--------------------------------------------------
	--------------------------------------------------
		Widgets :
	propriétés générales, texte, actu, rss,
	banniere, calendrier, raccourcis, météo, sondage,
	recommander le site, newsletter, publicité

--------------------------------------------------
--------------------------------------------------*/
body.page-defaut .header--boxes {
  background: #FFF;
  border-radius: 60px;
  padding: 0 0.5rem 0 2rem;
  margin-top: 1rem;
  margin-right: 27%;
  float: right;
  justify-content: center;
  z-index: 1031;
  position: relative;
}
@media (min-width: 1650px) {
  body.page-defaut .header--boxes {
    margin-right: 15%;
  }
}

.header--boxes {
  display: none;
}
@media (min-width: 820px) {
  .header--boxes {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
}
.header--boxes .box {
  padding: 1rem;
  padding-left: 2rem;
}
.header--boxes .box p {
  margin: 0;
}
.header--boxes .box a {
  color: #000;
}
.header--boxes .box a:hover {
  text-decoration: none;
}
.header--boxes .box:first-child a {
  position: relative;
}
.header--boxes .box:first-child a:before {
  content: "";
  font-family: "icomoon";
  left: -2rem;
  top: 50%;
  margin-top: -20%;
  position: absolute;
}
.header--boxes .box:nth-child(2) a {
  position: relative;
}
.header--boxes .box:nth-child(2) a:before {
  content: "";
  font-family: "icomoon";
  font-family: icomoon;
  left: -1.4rem;
  top: 50%;
  margin-top: -15%;
  position: absolute;
  font-size: 1.3rem;
}
.header--boxes .box:nth-child(2) a:after {
  content: "";
  position: absolute;
  left: -2rem;
  height: 27px;
  width: 1px;
  background: #DDD;
}
.header--boxes .box:nth-child(3) a {
  position: relative;
}
.header--boxes .box:nth-child(3) a:before {
  content: "";
  font-family: "icomoon";
  font-family: icomoon;
  left: -1.6rem;
  top: 50%;
  margin-top: -20%;
  position: absolute;
  font-size: 1.4rem;
}
.header--boxes .box:nth-child(3) a:after {
  content: "";
  position: absolute;
  left: -2rem;
  height: 27px;
  width: 1px;
  background: #DDD;
}

.box-bonnesraisons {
  margin-top: 5rem;
  position: relative;
  margin-bottom: 5rem;
  color: #FFF;
}
@media (min-width: 820px) {
  .box-bonnesraisons {
    min-height: 730px;
    margin-bottom: auto;
  }
}
.box-bonnesraisons .box-bonnesraisons-imgbg {
  width: 100%;
  height: 100%;
  position: absolute;
}
.box-bonnesraisons .box-bonnesraisons-imgbg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.box-bonnesraisons .box-bonnesraisons-content {
  margin-top: 3rem;
}
@media (min-width: 820px) {
  .box-bonnesraisons .box-bonnesraisons-content {
    margin-top: 10rem;
  }
  .box-bonnesraisons .box-bonnesraisons-content ul li {
    font-size: 1.3rem;
  }
}
.box-bonnesraisons .box-bonnesraisons-content strong {
  font-size: 3rem;
  line-height: 2rem;
}
.box-bonnesraisons .box-bonnesraisons-content em {
  font-size: 2rem;
  font-style: normal;
}
.box-bonnesraisons .box-bonnesraisons-content ul {
  padding: 0;
}
.box-bonnesraisons .box-bonnesraisons-content ul li {
  list-style: none;
  line-height: 2.5rem;
}
.box-bonnesraisons .box-bonnesraisons-content ul li:before {
  content: url("/media/plus.svg");
  margin-right: 0.5rem;
}
.box-bonnesraisons .box-bonnesraisons-avis {
  background: #FFF;
  border-radius: 30px;
  text-align: center;
  padding: 2rem 1rem;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-bonnesraisons .box-bonnesraisons-avis .v-card {
  border: 0 !important;
}
.box-bonnesraisons .box-bonnesraisons-avis .home__section-toptitle {
  color: #000;
}
.box-bonnesraisons .box-bonnesraisons-avis .home__section-toptitle:before {
  content: "";
  color: #8f9126;
  font-family: icomoon;
  position: absolute;
  top: -1.5rem;
  left: 50%;
  margin-left: -25px;
  opacity: 0.3;
  font-size: 0.9rem;
}

body.page-home .header--boxes {
  position: absolute;
  top: 1rem;
  left: 50%;
  margin-left: -144px;
  background: #1b2a48;
  border-radius: 60px;
  padding: 0 0.5rem 0 2rem;
  margin-top: 1rem;
  justify-content: center;
  z-index: 1031;
}
body.page-home .header--boxes a {
  color: #FFF;
}
@media (min-width: 820px) {
  body.page-home .header--boxes {
    left: 20%;
    margin-left: auto;
  }
}

.box-follow-us, .box-concept, .box-reservation {
  display: none;
}
@media (min-width: 820px) {
  .box-follow-us, .box-concept, .box-reservation {
    display: block;
  }
}

@media (max-width: 819.98px) {
  .box-reservation {
    display: block;
  }
  .box-reservation .box-highlight--desc {
    top: inherit !important;
    bottom: 4%;
    z-index: 99;
  }
}

/* Alerte infos */
.modal-header {
  border: 0;
}

.modal-titre {
  color: #cb4f24;
  text-align: center;
  width: 100%;
  font-size: 1.7rem;
}
.modal-titre:before {
  content: "";
  font-size: 1rem;
  font-family: icomoon;
  display: block;
}

a.box-highlight--link {
  background: #1b2a48;
  color: #FFF;
  border-radius: 20px;
  padding: 0.5rem 2rem;
  margin: 1rem;
  display: inline-block;
}
a.box-highlight--link:hover {
  text-decoration: none;
  background: #0d1423;
}

/* Suivez nous */
.box-follow-us .box-highlight--desc {
  display: block;
  width: 130px;
  height: 110px;
  background: #FFF;
  padding: 10px 5px;
  text-align: center;
  position: fixed;
  top: 20%;
  margin-top: 250px;
  right: 1%;
  color: #1b2a48;
  -webkit-border-radius: 15px;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 15px;
  -moz-border-radius-topright: 0;
  border-radius: 15px;
  border-top-right-radius: 0;
  z-index: 5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.box-follow-us .box-highlight--desc p {
  margin: 0;
  line-height: 1rem;
}
.box-follow-us .box-highlight--desc a {
  width: 42px;
  height: 42px;
  font-size: 1.3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  border-radius: 50%;
  background: #b7944c;
  margin-bottom: 10px;
}
.box-follow-us .box-highlight--desc a:hover {
  text-decoration: none;
  background: #94773c;
}

/* Box hebergement */
.box__hebergement-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 3% 10%;
  position: relative;
  z-index: 5;
  flex-wrap: wrap;
}
@media (min-width: 820px) {
  .box__hebergement-list {
    flex-wrap: nowrap;
  }
}
@media (max-width: 991.98px) {
  .box__hebergement-list {
    flex-wrap: wrap;
  }
}
.box__hebergement-list .box__hebergement-item {
  width: 365px;
  height: 270px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  color: #FFF;
  background: #000;
  margin: 1rem 0;
}
@media (min-width: 820px) {
  .box__hebergement-list .box__hebergement-item {
    width: 365px;
    height: 470px;
    margin: 0 10px;
  }
}
@media (max-width: 991.98px) {
  .box__hebergement-list .box__hebergement-item {
    margin-bottom: 14px;
  }
}
.box__hebergement-list .box__hebergement-item .box-highlight--desc:hover, .box__hebergement-list .box__hebergement-item .box-hebergement--title:hover {
  cursor: default;
}
.box__hebergement-list .box__hebergement-item .box-hebergement--title {
  font-size: 1.75rem;
  position: relative;
  font-weight: bold;
  color: #FFF;
}
@media (min-width: 820px) {
  .box__hebergement-list .box__hebergement-item .box-hebergement--title {
    height: 85px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
  }
}
.box__hebergement-list .box__hebergement-item img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s;
}
.box__hebergement-list .box__hebergement-item a {
  background: #b7944c;
  display: inline-block;
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  text-align: center;
  color: #FFF;
  transition: 0.3s;
}
.box__hebergement-list .box__hebergement-item a:hover {
  text-decoration: none;
  color: #333;
  background: #FFF;
}
.box__hebergement-list .box__hebergement-item:hover {
  background: none;
}
.box__hebergement-list .box__hebergement-item:hover img {
  filter: blur(3px);
  z-index: -1;
  opacity: 1;
}
.box__hebergement-list .box__hebergement-item:hover .box__hebergement-item--content {
  width: 100%;
  top: 0;
  justify-content: center;
}
.box__hebergement-list .box__hebergement-item:hover .box__hebergement-item--content .box-highlight--desc, .box__hebergement-list .box__hebergement-item:hover .box__hebergement-item--content .box-highlight--link {
  opacity: 1;
}
.box__hebergement-list .box__hebergement-item:hover .box__hebergement-item--content .box-highlight--desc {
  display: none;
}
@media (min-width: 820px) {
  .box__hebergement-list .box__hebergement-item:hover .box__hebergement-item--content .box-highlight--desc {
    display: block;
  }
}
.box__hebergement-list .box__hebergement-item:hover .box__hebergement-item--content:before {
  content: "";
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  z-index: -1;
}
.box__hebergement-list .box__hebergement-item:hover .box-hebergement--title {
  position: relative;
}
.box__hebergement-list .box__hebergement-item:hover .box-hebergement--title:after {
  content: url("/media/wave.svg");
  position: absolute;
  bottom: -14px;
  left: 50%;
  margin-left: -25px;
}
.box__hebergement-list .box__hebergement-item .box__hebergement-item--content {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 65%;
  transition: 0.3s;
  padding: 0 2rem;
  height: 100%;
}
@media (min-width: 820px) {
  .box__hebergement-list .box__hebergement-item .box__hebergement-item--content {
    top: 75%;
    height: 470px;
  }
}
@media (max-width: 991.98px) {
  .box__hebergement-list .box__hebergement-item .box__hebergement-item--content {
    top: 0;
    justify-content: center;
    width: 100%;
  }
}
.box__hebergement-list .box__hebergement-item .box__hebergement-item--content:before {
  content: "";
  background: #313337;
  background: -moz-linear-gradient(180deg, rgba(49, 51, 55, 0) 0%, black 100%);
  background: -webkit-linear-gradient(180deg, rgba(49, 51, 55, 0) 0%, black 100%);
  background: linear-gradient(180deg, rgba(49, 51, 55, 0) 0%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#313337",endColorstr="#000000",GradientType=1);
  height: 200px;
  width: 100%;
  display: block;
  position: absolute;
  top: -20px;
  left: 0;
}
@media (max-width: 991.98px) {
  .box__hebergement-list .box__hebergement-item .box__hebergement-item--content:before {
    display: none;
  }
}
.box__hebergement-list .box__hebergement-item .box__hebergement-item--content .box-highlight--desc, .box__hebergement-list .box__hebergement-item .box__hebergement-item--content .box-highlight--link {
  opacity: 0;
}
@media (max-width: 991.98px) {
  .box__hebergement-list .box__hebergement-item .box__hebergement-item--content .box-highlight--desc {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .box__hebergement-list .box__hebergement-item .box__hebergement-item--content .box-highlight--link {
    opacity: 1;
  }
}

/* Le concept */
.box-concept .box--title {
  display: none;
}
.box-concept .box-highlight--desc {
  display: block;
  width: 130px;
  height: 110px;
  background: #1b2a48;
  padding: 10px 5px;
  text-align: center;
  position: fixed;
  top: 20%;
  right: 1%;
  color: #FFF;
  margin-top: 125px;
  -webkit-border-radius: 15px;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 15px;
  -moz-border-radius-topright: 0;
  border-radius: 15px;
  border-top-right-radius: 0;
  z-index: 5;
}
.box-concept .box-highlight--desc img {
  width: 42px;
  margin-bottom: 10px;
}
.box-concept .box-highlight--desc p {
  margin: 0;
  line-height: 1rem;
}
.box-concept .box-highlight--desc a {
  color: #FFF;
  font-size: 0.9rem;
}
.box-concept .box-highlight--desc a:hover {
  text-decoration: none;
}

/* Le concept */
.box-reservation .box--title {
  display: none;
}
.box-reservation .box-highlight--desc {
  display: block;
  width: 85px;
  height: 70px;
  background: #b7944c;
  padding: 10px 5px;
  text-align: center;
  position: fixed;
  top: 20%;
  right: 1%;
  color: #FFF;
  -webkit-border-radius: 15px;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius: 15px;
  -moz-border-radius-topright: 0;
  border-radius: 15px;
  border-top-right-radius: 0;
  z-index: 5;
}
@media (min-width: 576px) {
  .box-reservation .box-highlight--desc {
    width: 130px;
    height: 110px;
  }
}
.box-reservation .box-highlight--desc img {
  width: 42px;
  margin-bottom: 10px;
}
.box-reservation .box-highlight--desc p {
  margin: 0;
  line-height: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box-reservation .box-highlight--desc a {
  color: #FFF;
  font-size: 0.8rem;
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-reservation .box-highlight--desc a:hover {
  text-decoration: none;
}
.box-reservation .box-highlight--desc a:before {
  content: "";
  font-family: "icomoon";
  font-size: 1.7rem;
  background: #FFF;
  padding: 0.5rem;
  border-radius: 50%;
  line-height: 1.7rem;
  color: #b7944c;
}
@media (min-width: 576px) {
  .box-reservation .box-highlight--desc a {
    font-size: 0.9rem;
  }
}

/* Gmap carte footer */
.box-gmap {
  position: relative;
}
.box-gmap .box-highlight--link {
  position: absolute;
  left: 50%;
  margin-left: -156px;
  top: 50%;
  margin-top: -22px;
  background: #1b2a48;
  color: #FFF;
  border-radius: 30px;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 0.5rem;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.box-gmap .box-highlight--link:hover {
  background: #0d1423;
  color: #FFF;
  text-decoration: none;
}
.box-gmap .box-highlight--link:before {
  content: "";
  font-family: "icomoon";
  margin: 0 0.5rem;
}

/* Logos footer */
.box-logos-footer {
  background: #FFF;
}
.box-logos-footer p {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

/* commons */
.no-title .box--title {
  display: none;
}

/* text (/boxes/text/box_text.html) */
/* shortcuts header (/boxes/shortcut/header_shortcut.html) */
/* shortcuts aside (/boxes/shortcut/tools_shortcut.html) */
/* shortcuts footer (/boxes/shortcut/footer_shortcut.html) */
/* last elements (/boxes/last_elements/last_elements.html) */
/* newsletter (/newsletters/box_newsletters.html) */
/* search (/search/box_search.html) */
/* box-social (/z-templates/social.tmpl.html) */
.box-social--list {
  margin-bottom: 0;
}

.box-social--item {
  display: inline-block;
  vertical-align: middle;
}

.box-social--link {
  background: #000;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 7px;
  display: block;
  text-decoration: none;
}

.cardhebergement__link .cardhebergement__infos {
  justify-content: flex-start;
  padding-top: 1rem;
}

/*	--------------------------------------------------
	--------------------------------------------------
		Communauté et ses widgets :
		notations, commentaires, tags
--------------------------------------------------
--------------------------------------------------*/
/* -------------------------------------------- */
/* ETOILES DES NOTATIONS                        */
/* -------------------------------------------- */
.ui-stars-cancel, .ui-stars-star {
  display: inline-block;
  width: 17px;
  height: 15px;
  text-indent: -999em;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
}

.ui-stars-cancel, .ui-stars-cancel a {
  background: url(/common_images/skin001/star-delete.gif) no-repeat 0 -16px;
}

.ui-stars-star, .ui-stars-star a {
  background: url(/common_images/skin001/star.gif) no-repeat 0 0;
}

.ui-stars-cancel a, .ui-stars-star a {
  display: block;
  width: 16px;
  height: 100%;
  background-position: 0 0;
}

.ui-stars-star-on a {
  background-position: 0 -16px !important;
}

.ui-stars-star-hover a {
  background-position: 0 -32px;
}

.ui-stars-cancel-hover a {
  background-position: 0 -16px;
}

.ui-stars-cancel-disabled a, .ui-stars-star-disabled,
.ui-stars-star-disabled a {
  cursor: default !important;
}

.ui-stars-star {
  background: transparent !important;
  overflow: hidden !important;
}

.gs-review-body {
  word-break: normal !important;
}

/*	--------------------------------------------------
	--------------------------------------------------
		Annuaire et ses widgets
--------------------------------------------------
--------------------------------------------------*/
.cardhebergement__box {
  padding-bottom: 15%;
}
@media (min-width: 820px) {
  .cardhebergement__box {
    padding-bottom: 0;
  }
}
.cardhebergement__box:hover .product_viewmore {
  background: #CCC;
}

.product_viewmore {
  background: #ECECEC;
  border-radius: 20px;
  position: absolute;
  bottom: 20px;
  right: 40%;
  padding: 0.5rem 1rem;
  transition: 0.3s;
}

.product_reserve {
  background: #b7944c;
  color: #FFF;
  border-radius: 20px;
  position: absolute;
  bottom: 20px;
  right: 5%;
  padding: 0.5rem 1rem;
  transition: 0.3s;
}
.product_reserve:hover {
  text-decoration: none;
  color: #FFF;
  background: #94773c;
}

/* box entries (/directory/boxes/entries.html) */
/**
*
* Navigation
* Menu, breadcrumb, pagination, pager, ...
*
**/
.dropdown-menu.show {
  width: 100% !important;
}

/* gestion du tactile quand le menu repasse en desktop */
@media (min-width: 820px) {
  .navbar-nav .nav-item:hover > .dropdown-menu, .navbar-nav .nav-item:focus > .dropdown-menu {
    display: block;
  }

  .no-touch .navbar-nav li:hover > ul.dropdown-menu {
    display: none;
  }

  .no-touch .navbar-nav li.open > ul.dropdown-menu {
    display: block;
  }
}
@media (min-width: 820px) and (min-width: 820px) {
  .fixed-top {
    right: 20%;
  }
}
@media (min-width: 820px) {
  .navbar-nav .dropdown-menu {
    margin-top: 0;
  }
}
@media (min-width: 820px) {
  .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
  }
}
@media (max-width: 819.98px) {
  .nav-item .dropdown-menu.open {
    display: block;
  }
}
@media (max-width: 819.98px) {
  .fixed-top {
    left: inherit !important;
  }
}

.navbar-collapse {
  justify-content: flex-end;
}

.navbar-dark .navbar-toggler {
  background: #1b2a48;
  border: 0;
  padding: 0;
  padding-bottom: 1rem;
  outline: none;
  z-index: 3;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 75px;
}
@media (min-width: 576px) {
  .navbar-dark .navbar-toggler {
    width: 80px;
    height: 90px;
  }
}
.navbar-dark .navbar-toggler .btn-close {
  opacity: 1;
  transition: 1s;
  height: 0;
}
.navbar-dark .navbar-toggler .btn-open {
  display: none;
}
.navbar-dark .navbar-toggler.collapsed .btn-open {
  display: block;
}
.navbar-dark .navbar-toggler.collapsed .btn-close {
  opacity: 0;
}
.navbar-dark span {
  color: #FFF;
  letter-spacing: 0.05rem;
  font-size: 0.8rem;
  bottom: 0.5rem;
}
@media (min-width: 576px) {
  .navbar-dark span {
    font-size: 0.9rem;
  }
}
.navbar-dark .line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.navbar-dark .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.navbar-dark .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.navbar-dark .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.navbar-dark .opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.navbar-dark .opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.navbar-dark .opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

#menu-main {
  margin-top: 90px;
  padding: 1rem;
}
@media (min-width: 820px) {
  #menu-main {
    padding: 0 0 0 100px;
    margin-top: 150px;
    position: relative;
    z-index: 10;
  }
}
#menu-main > li.nav-item {
  font-size: 1.5rem;
  font-weight: bold;
  opacity: 0.6;
  transition: 0.5s;
}
@media (min-width: 820px) {
  #menu-main > li.nav-item {
    font-size: 1.8rem;
  }
}
#menu-main > li.nav-item.active, #menu-main > li.nav-item.pager_current_page {
  opacity: 1;
}
#menu-main > li.nav-item:hover {
  opacity: 1;
}
#menu-main .dropdown-menu a {
  font-weight: normal;
  font-size: 1.4rem;
}

.navbar-collapse.show {
  animation: 0.1s slidein;
  animation-fill-mode: forwards;
  background: #1b2a48;
  color: #FFF;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  position: fixed;
  overflow-y: scroll;
}
@media (min-width: 820px) {
  .navbar-collapse.show {
    width: 50vw;
  }
}
.navbar-collapse.show .nav-item a {
  color: #FFF;
}
.navbar-collapse.show:after {
  display: none;
  content: url("/media/deco2.svg");
  position: absolute;
  bottom: 0;
  right: 30%;
  transform: rotate(-25deg) scale(1.5);
  z-index: 9;
}
@media (min-width: 820px) {
  .navbar-collapse.show:after {
    display: block;
  }
}

@keyframes slidein {
  from {
    right: -50vw;
  }
  to {
    right: 0;
  }
}
.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

li.dropdown > a {
  cursor: default;
}

.navbar-light .dropdown:hover > a, .navbar-light .dropdown:focus > a {
  color: rgba(0, 0, 0, 0.7);
}

/*=======================================
=            Animation Menu             =
=======================================*/
/* .dropdown{
    &.open, &:hover, &:focus{
        & > .dropdown-menu {
          -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
        }
    }
}

.dropdown-menu {
  transform-origin: top;
  animation-fill-mode: forwards;
  transform: scale(1, 0);
  display: block;
  transition: all 0.2s ease-out;
}
 */
/*=====  End of Animation Menu ======*/
/*	--------------------------------------------------
	--------------------------------------------------
		méthodes de publication:
	communs, actualités, articles, événements
--------------------------------------------------
--------------------------------------------------*/
/* Homepage */
.header-ushuaia-logo {
  position: absolute;
  z-index: 10;
  width: 20vw;
  top: 0;
  left: 0;
  max-width: 360px;
  min-width: 140px;
}

.page-home {
  position: relative;
}
.page-home .home-slider {
  position: absolute;
  top: 0;
  max-width: 100%;
}
.page-home .home-slider .slick-slide img {
  display: block;
  min-height: 800px;
  object-fit: cover;
}
@media (min-width: 820px) {
  .page-home .home-slider {
    max-height: 800px;
    overflow: hidden;
  }
  .page-home .home-slider img {
    max-height: 1000px;
    width: 100vw;
    object-fit: cover;
  }
}
.page-home .home-slider .slick-dots {
  position: absolute;
  bottom: 24%;
}
.page-home .home-slider .slick-dots li button:before {
  font-size: 3rem;
}

.home__hebergement {
  background: #1b2a48;
  background-repeat: no-repeat;
  color: #FFF;
  position: relative;
  padding-top: 5rem;
  text-align: center;
}
@media (min-width: 820px) {
  .home__hebergement {
    background: url("/images/int/bghebergement.svg") top center;
    background-repeat: no-repeat;
    padding-top: 10rem;
  }
}
.home__hebergement .home__hebergement-soustitre {
  font-size: 1.75rem;
  display: block;
  font-family: "Montserrat", cursive;
  margin-top: 0.5rem;
}
.home__hebergement .home__hebergement-titre {
  font-size: 2rem;
  font-weight: bold;
  display: block;
  margin-top: 1rem;
}
@media (min-width: 820px) {
  .home__hebergement .home__hebergement-titre {
    font-size: 2.8rem;
  }
}

.home__sitetitle {
  position: absolute;
  color: #FFF;
  z-index: 5;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 1000px;
  left: 50%;
  margin-left: -500px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  display: block;
  text-align: center;
  font-size: 1.7rem;
}
@media (min-width: 820px) {
  .home__sitetitle {
    top: 430px;
  }
}
@media (max-width: 991.98px) {
  .home__sitetitle {
    font-size: 16px;
    top: 290px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
  }
}

.home__hebergement-serarchbox {
  max-width: 800px;
  display: block;
  margin: 0 auto;
  margin-top: -100px;
}
@media (min-width: 820px) {
  .home__hebergement-serarchbox {
    height: 100px;
  }
}

.home__hebergement-serarchbox .v-application .primary--text {
  color: #1b2a48 !important;
  caret-color: #1b2a48 !important;
}
.home__hebergement-serarchbox .v-application .accent {
  background-color: #b7944c !important;
  border-color: #b7944c !important;
}

.home__section-image:before {
  display: none;
}
@media (min-width: 820px) {
  .home__section-image:before {
    display: block;
  }
}

.home__section-image--left {
  position: relative;
  margin-bottom: 50px;
}
.home__section-image--left:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #1b2a4852;
  border-radius: 440px 30px 30px 30px;
  position: absolute;
  top: 20px;
  left: -20px;
  z-index: -1;
  overflow: hidden;
}
.home__section-image--left .elementPicture {
  border-radius: 440px 30px 30px 30px;
  overflow: hidden;
}

.home__section-image--right {
  position: relative;
  margin-bottom: 50px;
}
.home__section-image--right:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #b7944c52;
  border-radius: 30px 440px 30px 30px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: -1;
  overflow: hidden;
}
.home__section-image--right .elementPicture {
  border-radius: 30px 440px 30px 30px;
  overflow: hidden;
}

.home__section-decouvrir-image {
  position: relative;
}
.home__section-decouvrir-image .elementPicture {
  border-radius: 440px 30px 30px 30px;
  overflow: hidden;
}

.home__section {
  padding: 3rem 0;
}
.home__section .table td {
  padding: 0;
}
@media (min-width: 820px) {
  .home__section {
    margin: 5rem 0;
  }
}
.home__section.home__activites {
  color: #1b2a48;
  position: relative;
}
.home__section.home__activites:before {
  display: none;
}
@media (min-width: 820px) {
  .home__section.home__activites:before {
    content: url("/images/int/deco3.svg");
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}
@media screen and (max-width: 960px) {
  .home__section.home__activites .col-md-6.pl-5 {
    padding-left: 1rem !important;
  }
}
.home__section.home__activites .home__section-image {
  position: relative;
  display: block;
}
.home__section.home__activites .home__section-image:before {
  content: "";
  font-family: "icomoon";
  font-size: 25rem;
  bottom: -10rem;
  left: -10rem;
  position: absolute;
}
.home__section.home__activites .home__section-link a {
  background: #b7944c;
  color: white;
  padding: 1rem 2rem;
  border-radius: 2rem;
  margin-top: 1rem;
}
.home__section.home__activites .home__section-link a:hover {
  background: #ba9853;
  text-decoration: none;
}
.home__section.home__activites ul {
  margin-top: 2rem;
}
.home__section.home__activites ul li {
  padding-left: 4rem;
  position: relative;
  margin: 0 0 1rem 0;
}
.home__section.home__activites ul li:before {
  font-family: "icomoon";
  position: absolute;
  margin-left: -3rem;
  font-size: 1.8rem;
}
@media (min-width: 820px) {
  .home__section.home__activites ul li:before {
    margin-left: -4rem;
  }
}
.home__section.home__activites .home__section-content td img {
  width: 40px;
}
.home__section.home__services {
  color: #1b2a48;
  position: relative;
}
.home__section.home__services:before {
  display: none;
}
@media (min-width: 820px) {
  .home__section.home__services:before {
    content: url("/images/int/deco4.svg");
    position: absolute;
    right: 0;
    top: -13rem;
    z-index: 1;
  }
}
@media screen and (max-width: 960px) {
  .home__section.home__services .col-md-6.pr-5 {
    padding-right: 0;
  }
}
.home__section.home__services .home__section-image {
  position: relative;
  display: block;
  margin-top: 4rem;
}
@media (min-width: 820px) {
  .home__section.home__services .home__section-image {
    margin-top: auto;
  }
}
.home__section.home__services .home__section-image:before {
  content: "";
  font-family: "icomoon";
  font-size: 25rem;
  bottom: -10rem;
  right: -10rem;
  position: absolute;
}
.home__section.home__services .home__section-link a {
  background: #b7944c;
  color: white;
  padding: 1rem 2rem;
  border-radius: 2rem;
  margin-top: 1rem;
}
.home__section.home__services .home__section-link a:hover {
  background: #ba9853;
  text-decoration: none;
}
.home__section.home__services ul {
  margin-top: 2rem;
}
.home__section.home__services ul li {
  padding-left: 4rem;
  position: relative;
  margin: 0 0 1rem 0;
  /*Pain*/
  /*Glace*/
  /*Snack*/
  /*Wifi*/
  /*vélo*/
  /*Food truck*/
}
.home__section.home__services ul li:before {
  font-family: "icomoon";
  position: absolute;
  margin-left: -3rem;
  font-size: 1.8rem;
}
@media (min-width: 820px) {
  .home__section.home__services ul li:before {
    margin-left: -4rem;
  }
}
.home__section.home__services ul li:first-child:before {
  content: "";
}
.home__section.home__services ul li:nth-child(2):before {
  content: "";
}
.home__section.home__services ul li:nth-child(3):before {
  content: "";
}
.home__section.home__services ul li:nth-child(4):before {
  content: "";
}
.home__section.home__services ul li:nth-child(5):before {
  content: "";
}
.home__section.home__services ul li:nth-child(6):before {
  content: "";
}
.home__section-toptitle {
  font-family: "Montserrat", cursive;
  font-size: 1.5rem;
  position: relative;
}
.home__section-toptitle:before {
  content: "";
  font-family: "icomoon";
  position: absolute;
  top: -2rem;
  opacity: 0.3;
  font-size: 0.9rem;
}
.home__section p {
  margin-top: 1rem;
  color: #333;
  /*
  strong {
     font-size: 3.3rem;
     line-height: 3rem;
  }
  */
}
.home__section p:first-child {
  color: inherit;
}
.home__section h2 {
  font-weight: bold;
}
.home__section ul {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
.home__section ul li {
  list-style: none;
}
@media (min-width: 820px) {
  .home__section ul li {
    width: 50%;
  }
}
.home__section ul li strong {
  font-size: 1rem;
}

.home__section-decouvrir {
  background: #1b2a48;
  color: #FFF;
  position: relative;
  padding: 5rem 0 10rem 0;
  margin-top: 5rem;
}
.home__section-decouvrir:before {
  display: none;
}
@media (min-width: 820px) {
  .home__section-decouvrir:before {
    content: url("/images/int/deco5.svg");
    position: absolute;
    right: -8rem;
    bottom: 5rem;
    z-index: 1;
    transform: rotate(8deg);
  }
}
.home__section-decouvrir .container {
  z-index: 1;
}
@media (min-width: 820px) {
  .home__section-decouvrir .container:after {
    position: absolute;
    content: "";
    width: 2000px;
    left: 50%;
    margin-left: -1000px;
    height: 200px;
    background: #fff;
    border-radius: 50%;
    bottom: -100px;
    z-index: 0;
  }
}
.home__section-decouvrir .container h2 {
  font-size: 3.3rem;
  text-transform: capitalize;
  font-weight: bold;
}
.home__section-decouvrir .home__section-decouvrir-image {
  position: relative;
  display: block;
  margin-top: -10rem;
}
@media (min-width: 820px) {
  .home__section-decouvrir .home__section-decouvrir-image {
    margin-left: -4rem;
  }
}
.home__section-decouvrir .home__section-decouvrir-image:before {
  display: none;
}
@media (min-width: 820px) {
  .home__section-decouvrir .home__section-decouvrir-image:before {
    content: "";
    font-family: "icomoon";
    font-size: 25rem;
    bottom: -8rem;
    right: -2rem;
    position: absolute;
    opacity: 0.2;
  }
}
.home__section-decouvrir .home__section-link a {
  border: 4px solid #FFF;
  color: white;
  padding: 1rem 2rem;
  border-radius: 2rem;
  margin-top: 1rem;
  display: inline-block;
  transition: 0.3s;
}
.home__section-decouvrir .home__section-link a:hover {
  text-decoration: none;
  background: #FFF;
  color: #1b2a48;
}
.home__section-decouvrir .icon-decosignature {
  font-size: 14rem;
  position: absolute;
  left: 53%;
  bottom: 1.5rem;
  opacity: 0.1;
  display: none;
}
@media (min-width: 820px) {
  .home__section-decouvrir .icon-decosignature {
    display: block;
  }
}

.home__actus strong {
  font-size: 3.3rem;
  line-height: 3rem;
}
.home__actus .home__section-toptitle {
  color: #1b2a48;
}
.home__actus .card {
  border: none;
}
.home__actus .card .box-highlight-posts--img img {
  width: 100%;
  max-height: 330px;
  object-fit: cover;
}
.home__actus .card .card-body {
  padding: 1.25rem 0;
}
.home__actus .card .box-highlight-posts--date {
  display: none;
}
.home__actus .card .card-title {
  font-weight: bold;
  font-size: 1.3rem;
}
.home__actus .card .card-footer {
  background: #FFF;
  border: none;
  padding: 0;
  margin-top: 1rem;
}
.home__actus .card .card-footer a {
  border: 2px solid;
  color: #000;
  border-radius: 3rem;
  padding: 1rem 3rem;
  transition: 0.3s;
}
.home__actus .card .card-footer a:hover {
  text-decoration: none;
  background: #000;
  color: #FFF;
}

.home__actus-linkall a {
  color: #FFF;
  background: #1b2a48;
  position: relative;
  border-radius: 3rem;
  padding: 1rem 3rem;
  border: 4px solid #1b2a48;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0 auto;
  display: block;
  width: fit-content;
}
.home__actus-linkall a:hover {
  color: #1b2a48;
  background: #FFF;
  text-decoration: none;
}

.home_prixmalin {
  margin-top: 5rem;
  overflow: hidden;
}
@media (min-width: 820px) {
  .home_prixmalin {
    margin-top: auto;
  }
}
.home_prixmalin strong {
  font-size: 3.3rem;
  line-height: 3rem;
  color: #b7944c;
}
.home_prixmalin .home__section-toptitle {
  color: #b7944c;
}
.home_prixmalin .box-highlight {
  position: relative;
  background: #b7944c;
  color: #FFF;
  text-align: center;
  border-radius: 30px;
  max-height: 600px;
  padding-top: 10px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.home_prixmalin .box-highlight:before {
  content: url("/images/int/decoprixmalin.svg");
  position: absolute;
  left: 0;
  top: 20%;
  width: 100%;
}
.home_prixmalin .box-highlight strong {
  color: white;
}
.home_prixmalin .box-highlight .box-highlight--link {
  position: relative;
  background: #FFF;
  border-radius: 3rem;
  color: #b7944c;
  padding: 1rem 3rem;
  border: 4px solid #b7944c;
  transition: 0.3s;
}
.home_prixmalin .box-highlight .box-highlight--link:hover {
  background: #b7944c;
  border: 4px solid #FFF;
  text-decoration: none;
  color: #FFF;
}

/* Page annuaire */
.catalog__serchbox {
  bottom: 50px;
  z-index: 9;
  position: relative;
  width: 100%;
}
@media (min-width: 820px) {
  .catalog__serchbox {
    display: block;
  }
}

/*
.catalog__serchbox {display: none;}
 body.directoryEntriesList, body.directoryEntryDetail  {
    .catalog__serchbox {
      position: absolute;
      bottom: 30px;
      width: 80%;
      margin-left: 10%;
      @include media-breakpoint-up(md) {
         display: block;
      }
    }
 }
 */
/* Annuaire détail */
.hebergement__photosslider, .cardhebergement__photos {
  height: 100%;
}
.hebergement__photosslider img, .cardhebergement__photos img {
  height: 405px;
  width: 100%;
  object-fit: cover;
}
.hebergement__photosslider a.slick-slide:hover, .cardhebergement__photos a.slick-slide:hover {
  cursor: zoom-in;
}
.hebergement__photosslider .slick-prev, .hebergement__photosslider .slick-next, .cardhebergement__photos .slick-prev, .cardhebergement__photos .slick-next {
  z-index: 5;
  background: #FFF;
  border-radius: 50%;
  top: 75%;
  height: 30px;
  width: 30px;
}
.hebergement__photosslider .slick-prev:before, .hebergement__photosslider .slick-next:before, .cardhebergement__photos .slick-prev:before, .cardhebergement__photos .slick-next:before {
  color: #1b2a48;
  font-size: 0.9rem;
}
.hebergement__photosslider .slick-prev:hover, .hebergement__photosslider .slick-next:hover, .cardhebergement__photos .slick-prev:hover, .cardhebergement__photos .slick-next:hover {
  background: #1b2a48;
}
.hebergement__photosslider .slick-prev:hover:before, .hebergement__photosslider .slick-next:hover:before, .cardhebergement__photos .slick-prev:hover:before, .cardhebergement__photos .slick-next:hover:before {
  color: #FFF;
}
.hebergement__photosslider .slick-prev, .cardhebergement__photos .slick-prev {
  left: 40%;
}
.hebergement__photosslider .slick-next, .cardhebergement__photos .slick-next {
  right: 40%;
}

.fancybox-slide--complete:hover {
  cursor: zoom-out;
}

.hebergement__title {
  color: #8f9126;
  font-size: 2rem;
  line-height: 2rem;
  position: relative;
}
.hebergement__title strong {
  font-size: 2.8rem;
}
.hebergement__title:before {
  content: "";
  font-family: icomoon;
  position: absolute;
  top: -30px;
  opacity: 0.5;
  font-size: 1rem;
}

.hebergement__tarif {
  background: #1b2a48;
  color: #FFF;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding: 0.5rem 1rem;
  margin: 2rem 0;
  align-items: center;
}
.hebergement__tarif .hebergement__tarifs {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  flex: 3;
}
.hebergement__tarif .hebergement__tarifs span {
  font-weight: normal;
  font-size: 1rem;
}
.hebergement__tarif .hebergement__tarif-type {
  padding: 0 0.5rem;
  text-align: center;
  flex: 3;
}

.hebergement__infos span {
  width: 50px;
  font-size: 1.3rem;
  text-align: center;
}
.hebergement__infos .hebergement__infos-pers {
  margin: 1rem 0;
}
.hebergement__infos .hebergement__infos-pers span {
  font-size: 1.7rem;
}

.reservation__title {
  color: #cb4f24;
  font-size: 2rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
}

/* Annuaire découvrir */
.box__decouvrir {
  height: 300px;
  overflow: hidden;
}
.box__decouvrir a {
  position: relative;
  display: block;
  height: 100%;
  background: #000;
  border-radius: 10px;
}
.box__decouvrir a .box__decouvrir-title {
  color: #FFF;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}
.box__decouvrir a:hover img {
  opacity: 0.4;
}
.box__decouvrir a:hover .box__decouvrir-title {
  color: #FFF;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}
.box__decouvrir a:hover .box__decouvrir-details {
  display: block;
}
.box__decouvrir img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
  transition: 0.3s;
}

.box__decouvrir-infos {
  position: absolute;
  font-size: 1.5rem;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: column;
  padding: 1.5rem;
}

.box__decouvrir-title {
  font-weight: bold;
}

.box__decouvrir-details {
  font-size: 0.9rem;
  font-weight: normal;
  color: #FFF;
  display: none;
}

/* Liste hebegements */
.cardhebergement__box {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin: 1rem auto;
  overflow: hidden;
  transition: 0.3s;
  width: 100%;
  position: relative;
}
.cardhebergement__box:hover {
  text-decoration: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
@media (min-width: 820px) {
  .cardhebergement__box {
    flex-direction: row;
    height: 317px;
  }
}
.cardhebergement__box .cardhebergement__photos {
  width: 350px;
  height: 317px;
  display: block;
  flex: 1;
}
@media (max-width: 819.98px) {
  .cardhebergement__box .cardhebergement__photos {
    width: 100%;
  }
}
.cardhebergement__box .cardhebergement__photos img {
  height: 317px;
  object-fit: cover;
}

.cardhebergement__description {
  font-size: 0.9rem;
}

.cardhebergement__link {
  color: #333;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex: 2;
  justify-content: space-between;
}
@media (max-width: 819.98px) {
  .cardhebergement__link {
    flex-wrap: wrap;
  }
}
.cardhebergement__link:hover {
  text-decoration: none;
  color: inherit;
}
.cardhebergement__link .cardhebergement__content {
  padding: 1rem;
  max-width: 590px;
}
.cardhebergement__link .cardhebergement__content .cardhebergement__pers {
  font-weight: bold;
  margin: 1rem 0;
}
.cardhebergement__link .cardhebergement__title {
  font-size: 2rem;
  font-weight: bold;
  color: #1b2a48;
}
.cardhebergement__link .cardhebergement__infos {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
@media (max-width: 819.98px) {
  .cardhebergement__link .cardhebergement__infos {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.cardhebergement__link .cardhebergement__infos .cardhebergement__typeprice {
  background: #1b2a48;
  color: #FFF;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}
@media (max-width: 819.98px) {
  .cardhebergement__link .cardhebergement__infos .cardhebergement__typeprice {
    text-align: right;
  }
}
.cardhebergement__link .cardhebergement__infos .cardhebergement__price--off {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.cardhebergement__link .cardhebergement__infos .cardhebergement__price {
  padding: 0 0.5rem;
  text-align: right;
  font-size: 0.9rem;
}
@media (max-width: 819.98px) {
  .cardhebergement__link .cardhebergement__infos .cardhebergement__price {
    width: 100%;
  }
}
.cardhebergement__link .cardhebergement__infos .cardhebergement__price span {
  font-weight: bold;
  font-size: 1.8rem;
  display: block;
}

.autreshebergements {
  display: none;
}

body.directoryEntriesList .autreshebergements, body.hebergement .autreshebergements {
  display: block;
  overflow: hidden;
  background: #1b2a48;
  color: #FFF;
  padding-top: 7em;
  position: relative;
}
body.directoryEntriesList .autreshebergements span, body.hebergement .autreshebergements span {
  font-weight: bold;
  position: relative;
  color: #FFF;
  display: block;
  text-align: center;
  font-size: 2rem;
}
body.directoryEntriesList .autreshebergements span:before, body.hebergement .autreshebergements span:before {
  content: "";
  position: absolute;
  font-family: "icomoon";
  left: 50%;
  top: -2rem;
  font-size: 1rem;
  margin-left: -28px;
  opacity: 0.5;
}
body.directoryEntriesList .autreshebergements:before, body.hebergement .autreshebergements:before {
  content: url("/images/bgdefaut2.svg");
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -1192px;
  transform: scaleY(-1);
}

/* Page contact */
.form-entete {
  color: #1b2a48;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  display: block;
}
.form-entete:before {
  content: "";
  opacity: 0.5;
  font-family: "icomoon";
  margin-top: -35px;
  position: absolute;
}
.form-entete:after {
  content: "";
  font-family: "icomoon";
  font-size: 0.5rem;
}

.infos_camping {
  margin: 4rem 0;
}
.infos_camping-adresse, .infos_camping-email {
  color: #1b2a48;
  font-weight: bold;
}
.infos_camping span {
  position: relative;
}
.infos_camping span.icon {
  position: absolute;
  margin-left: -1.8rem;
}
.infos_camping-adresse span.icon {
  font-size: 1.5rem;
}
.infos_camping-logo {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 2rem 0;
}
.infos_camping-logo > * {
  flex: 40%;
  max-width: 40%;
}

.contact_image {
  position: relative;
}
@media (min-width: 820px) {
  .contact_image:before {
    content: "";
    font-family: icomoon;
    font-size: 25rem;
    bottom: -10rem;
    left: -10rem;
    position: absolute;
    color: #b7944c;
  }
}

/* Actualités */
.actualites h1 {
  text-transform: capitalize;
}

.post-item {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.post-item a {
  transition: 0.3s;
}
.post-item a h2 {
  font-size: 1.5rem;
  color: #222;
}
.post-item a:hover {
  text-decoration: none;
}

/* Footer */
.box-highlight--desc, .box-highlight--title {
  text-align: center;
}
@media (min-width: 820px) {
  .box-highlight--desc, .box-highlight--title {
    text-align: inherit;
  }
}

.box-highlight--title {
  font-weight: bold;
}

.adresse_footer a.adresse_footer-adresse {
  background: #FFF;
  color: #000;
  border-radius: 30px;
  padding: 10px 20px;
  display: inline-block;
  margin-bottom: 0.5rem;
  transition: 0.3s;
}
.adresse_footer a.adresse_footer-adresse:hover {
  background: #b7944c;
  color: #000;
  text-decoration: none;
}
.adresse_footer a.adresse_footer-localisation {
  background: #b7944c;
  color: #000;
  border-radius: 30px;
  padding: 10px 20px;
  transition: 0.3s;
}
.adresse_footer a.adresse_footer-localisation:hover {
  background: #FFF;
  text-decoration: none;
}

.footer_suiveznous a {
  font-size: 1.8rem;
  color: #FFF;
  padding: 0 0.5rem;
}
.footer_suiveznous .box-highlight--desc p:first-child {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-around;
}
@media (min-width: 820px) {
  .footer_suiveznous .box-highlight--desc p:first-child {
    width: 150px;
  }
}

/* media (bootstrap) : présentation famille, header news detail, header event detail, structure bloc&image emajine */
.media {
  flex-wrap: wrap;
}

@media (min-width: 576px) {
  .media {
    flex-wrap: nowrap;
  }

  .media-img {
    max-width: 25%;
  }
}
/*card (short-product, article list, news list, event-list, social wall, ...)*/
/* commons (multiple) */
/* news (/publication_methods/news/news.html) */
/* articles (/publication_methods/articles/multi_articles.html) */
/* events (/events/events.html) */
/* Comments (/community/comments/list_comments.html) */
/* Attention les commentaires sont utilisé dans plusieurs modules.
vous retrouverez les classes spécifique aux comments product dans la fiche de style catalog.less par exemple */
/* Comments form (/community/comments/form_add_comment.html ) */
/** RESPONSIVE **/
/** Personnalisation **/
/*	--------------------------------------------------
	--------------------------------------------------
		Gestion des fonts.
		Possibilité de créer une mixin par font

	--------------------------------------------------
	--------------------------------------------------*/
/** ICOMOON **/
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon/icomoon.eot");
  src: url("fonts/icomoon/icomoon.eot") format("embedded-opentype"), url("fonts/icomoon/icomoon.ttf") format("truetype"), url("fonts/icomoon/icomoon.woff") format("woff"), url("fonts/icomoon/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-], [class*=" icon-"], .icomoon, .slick-dots li button:before, .slick-prev:before, .slick-next:before, .diaporamaControlNext:before, .diaporamaControlPrev:before, .btn-prev:before, .btn-next:after, .btn-back:before {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zephyr:before {
  content: "";
}

.icon-cercle_vague_ok:before {
  content: "";
}

.icon-cercle_vague:before {
  content: "";
  color: #857f00;
}

.icon-chevron_haut:before {
  content: "";
}

.icon-chevron_d:before {
  content: "";
}

.icon-chevron_g:before {
  content: "";
}

.icon-adresse:before {
  content: "";
}

.icon-aire_jeux:before {
  content: "";
}

.icon-aquagym:before {
  content: "";
}

.icon-arrow:before {
  content: "";
}

.icon-balade:before {
  content: "";
}

.icon-bed:before {
  content: "";
}

.icon-cercle_vague1:before {
  content: "";
}

.icon-decosignature:before {
  content: "";
}

.icon-Fb:before {
  content: "";
}

.icon-foodtruck:before {
  content: "";
}

.icon-glace:before {
  content: "";
}

.icon-Insta:before {
  content: "";
}

.icon-laverie:before {
  content: "";
}

.icon-logo_blanc:before {
  content: "";
}

.icon-loupe:before {
  content: "";
}

.icon-mail1:before {
  content: "";
}

.icon-marcheaqua:before {
  content: "";
}

.icon-multisport:before {
  content: "";
}

.icon-pain:before {
  content: "";
}

.icon-people:before {
  content: "";
}

.icon-petanque:before {
  content: "";
}

.icon-picto_citation:before {
  content: "";
}

.icon-player:before {
  content: "";
}

.icon-pool:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-stars:before {
  content: "";
}

.icon-superficie:before {
  content: "";
}

.icon-Tripadv:before {
  content: "";
}

.icon-vague_3:before {
  content: "";
}

.icon-velo:before {
  content: "";
}

.icon-wifi:before {
  content: "";
}

.icon-smartphone:before {
  content: "";
}

.icon-locked:before {
  content: "";
}

.icon-secure:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-close:before {
  content: "";
}

.icon-done:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-remove-user:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-megaphone:before {
  content: "";
}

.icon-chevron-small-left:before {
  content: "";
}

.icon-chevron-small-right:before {
  content: "";
}

.icon-chevron-thin-left:before {
  content: "";
}

.icon-chevron-thin-right:before {
  content: "";
}

.icon-location-pin:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-bin:before {
  content: "";
}

.icon-trashcan:before {
  content: "";
}

.icon-remove:before {
  content: "";
}

.icon-delete:before {
  content: "";
}

.icon-recycle:before {
  content: "";
}

.icon-dispose:before {
  content: "";
}

.icon-folder-plus:before {
  content: "";
}

.icon-directory:before {
  content: "";
}

.icon-folder-add:before {
  content: "";
}

.icon-cog:before {
  content: "";
}

.icon-gear:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-generate:before {
  content: "";
}

.icon-make:before {
  content: "";
}

.icon-options:before {
  content: "";
}

.icon-switch:before {
  content: "";
}

.icon-power:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-printer:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-tripadvisor:before {
  content: "";
}

.icon-pinterest:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-chevron-double-small-right:before {
  content: "";
}

.icon-chevron-double-small-left:before {
  content: "";
}

.icon-chevron-double-thin-left:before {
  content: "";
}

.icon-chevron-double-thin-right:before {
  content: "";
}

.icon-google:before {
  content: "";
}

.icon-shopping-cart:before {
  content: "";
}

/**
*
* Formulaires
*
**/
/*========================================
=            Form par default            =
========================================*/
/*----------  Header / Footer  ----------*/
.form--header, .form--footer {
  margin-top: 20px;
}

/*----------  Checkbox  ----------*/
/*----------  Checkbox  ----------*/
/*----------  Checkbox & Radio other  ----------*/
.checkbox--other label, .radio--other label {
  width: auto;
}
.checkbox--other input[type=checkbox], .radio--other input[type=checkbox] {
  margin-top: 8px;
}

.checkbox--other-input, .radio--other-input {
  width: auto;
  display: inline;
  margin-left: 1rem;
}

/*----------  Radios visual  ----------*/
/*=====  End of Form par default  ======*/
/**
*
* Composants spécifique au CMS saytup
*
**/
.page-element {
  margin-bottom: 15px;
}

/* ---------------------------------------- */
/*     Modules                              */
/* ---------------------------------------- */
.intext_left {
  float: left;
  margin: 0 40px 40px 0;
}

.intext_right {
  float: right;
  margin: 0 40px 40px;
}

.above_center, .below_center {
  text-align: center;
}

.above_right, .below_right {
  text-align: right;
}

.above_left, .below_left {
  text-align: left;
}

/* ---------------------------------------- */
/*     gallery                              */
/* ---------------------------------------- */
.gallery-img {
  height: 190px;
  overflow: hidden;
  margin-bottom: 30px;
}
.gallery-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  font-family: "object-fit:cover;";
}

/*=============================================
=            		CALENDAR     		     =
=============================================*/
.calendar {
  margin-bottom: 0;
  border: 1px solid #ccc;
}
.calendar caption {
  padding: 5px 0;
  color: #fff;
  background: #428bca;
}
.calendar thead {
  background: #eee;
}
.calendar.table > tbody > tr > td {
  height: 40px;
  padding: 0;
  line-height: 0;
  background: #fff;
  text-align: center;
  vertical-align: middle;
}
.calendar.table > tbody > tr > td a {
  display: block;
  height: 40px;
  padding: 0 8px;
  line-height: 40px;
}
.calendar.table > tbody > tr > td a:hover {
  background-color: #d9edf7;
}
.calendar.table > tbody > tr > td.today {
  color: #fff;
}
.calendar.table > tbody > tr > td.today a {
  color: #fff;
  background: #428bca;
}
.calendar.table > tbody > tr > td.no_day {
  background: #f6f6f6;
}

/*=====  End of CALENDAR  ======*/
/*================================
=            Notation            =
================================*/
.ui-stars-star {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  font-size: 0;
  background: url("/images/star-off.png") no-repeat -2px 0;
}

.ui-stars-star:nth-child(even) {
  background-position: -10px 0;
}

.ui-stars-star-on {
  background: url("/images/star-on.png") no-repeat -2px 0;
}

.ui-stars-star-hover {
  background: url("/images/star-on.png") no-repeat -2px 0;
}

.rating-stars {
  display: inline-block;
  height: 15px;
}

.nb_ratings {
  color: #737373;
}

.rating-value {
  margin-left: 20px;
}

/*=====  End of Notation  ======*/
/*==================================
=            Alignement            =
==================================*/
.txtLeft {
  text-align: left;
}

.txtCenter {
  text-align: center;
}

.txtRight {
  text-align: right;
}

.posLeft {
  float: left;
}

.posMiddle {
  margin: 0 auto;
}

.posRight {
  float: right;
}

.posMiddle {
  margin: 0 auto 16px;
  text-align: center;
}

.posRight {
  margin: 0 auto 16px;
  text-align: center;
}

@media (min-width: 576px) {
  .posLeft {
    float: left;
    margin-right: 30px;
    text-align: left;
  }

  .posRight {
    float: right;
    margin-left: 30px;
    text-align: right;
  }
}
/*=====  End of Alignement  ======*/
/*=========================================
=            Tailles générées             =
=========================================*/
.size1_1 {
  width: 100%;
  padding: 0 15px;
}

.size1_2 {
  padding: 0 15px;
}

.size1_3 {
  padding: 0 15px;
}

.size1_4 {
  padding: 0 15px;
}

.size1_5 {
  padding: 0 15px;
}

.size1_6 {
  padding: 0 15px;
}

.size1_7 {
  padding: 0 15px;
}

.size1_8 {
  padding: 0 15px;
}

@media (min-width: 576px) {
  .size1_1 {
    width: 100%;
    padding: 0 15px;
  }

  .size1_2 {
    width: 50%;
    padding: 0 15px;
  }

  .size1_3 {
    width: 33.33%;
    padding: 0 15px;
  }

  .size1_4 {
    width: 25%;
    padding: 0 15px;
  }

  .size1_5 {
    width: 20%;
    padding: 0 15px;
  }

  .size1_6 {
    width: 16.66%;
    padding: 0 15px;
  }

  .size1_7 {
    width: 14.285%;
    padding: 0 15px;
  }

  .size1_8 {
    width: 12.5%;
    padding: 0 15px;
  }
}
/*=====  End of Tailles générées   ======*/
/*=======================================
=            Alerte saytup            =
=======================================*/
/* etoile pour champs obligatoire */
.obligatory {
  color: #dc3545;
}

.form .invalid-feedback {
  display: block;
}

/* Message "Champ obligatoire" */
/* Etat invalide des champs */
/* Reste à tester */
/* validation de l'inscription au compte : Votre compte est maintenant actif. Un message comprenant votre login et votre mot de passe a été envoyé à l'adresse ...*/
/*=================================
=            Diaporama            =
=================================*/
.diaporamaSimpleSlideshow {
  margin: 20px 0;
}
@media (max-width: 819.98px) {
  .diaporamaSimpleSlideshow {
    width: 360px !important;
  }
  .diaporamaSimpleSlideshow img {
    object-fit: cover;
  }
}
.diaporamaSimpleSlideshow > div > span {
  display: none;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
}

.diaporamaSlide {
  background: #fff;
}

.diaporamaControlPrev {
  padding: 20px;
  margin-top: -30px;
  cursor: pointer;
  line-height: 1;
  border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  -webkit-border-radius: 0 8px 8px 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.diaporamaControlPrev:hover {
  padding-left: 30px;
}
.diaporamaControlPrev:before {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  color: #212529;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.diaporamaControlNext {
  padding: 20px;
  margin-top: -30px;
  cursor: pointer;
  line-height: 1;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
}
.diaporamaControlNext:hover {
  padding-right: 30px;
}
.diaporamaControlNext:before {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  color: #212529;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.diaporamaDots > div {
  position: relative;
  display: inline-block;
  padding: 10px 0;
  line-height: 9px;
  /* background: #fff; */
}
.diaporamaDots span {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 3px;
  cursor: pointer;
  text-indent: -9999px;
  background: #fff;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.diaporamaDots span:hover, .diaporamaDots span.diaporamaDotActive {
  background: #4f77aa;
}

.diaporamaExtra > div {
  display: inline-block;
  padding: 10px;
  color: #222;
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
}

.diaporamaExtraMiddleLeft,
.diaporamaExtraMiddleRight,
.diaporamaExtraMiddleCenter {
  padding: 0 70px;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.diaporamaExtraTopLeft,
.diaporamaExtraTopRight,
.diaporamaExtraTopCenter {
  padding: 10px;
}

.diaporamaExtraBottomLeft,
.diaporamaExtraBottomRight,
.diaporamaExtraBottomCenter {
  padding: 10px 10px 40px;
}

.diaporamaExtraTopCenter,
.diaporamaExtraBottomCenter,
.diaporamaExtraMiddleCenter,
.diaporamaExtra.diaporamaExtraTopCenter > div,
.diaporamaExtra.diaporamaExtraBottomCenter > div,
.diaporamaExtra.diaporamaExtraMiddleCenter > div {
  text-align: center;
}

.diaporamaExtraTopRight,
.diaporamaExtraMiddleRight,
.diaporamaExtraBottomRight {
  text-align: right;
}

.diaporamaExtra strong {
  display: block;
}

/*=====  End of Diaporama  ======*/
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url(/images/fancybox/fancybox_sprite.png);
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url(/images/fancybox/fancybox_loading.gif) center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url(/images/fancybox/blank.gif);
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */
.fancybox-lock {
  overflow: visible !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url(/images/fancybox/fancybox_overlay.png);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url(/images/fancybox/fancybox_sprite@2x.png);
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }

  #fancybox-loading div {
    background-image: url(/images/fancybox/fancybox_loading@2x.gif);
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}
/* slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: black;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
.slick-prev[dir=rtl] {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "";
}
.slick-prev:before[dir=rtl] {
  content: "";
}

.slick-next {
  right: -25px;
}
.slick-next[dir=rtl] {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "";
}
.slick-next:before[dir=rtl] {
  content: "";
}

/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: white;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8rem;
  background: #FFF;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
.slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*fancybox 3*/