/*	--------------------------------------------------
	--------------------------------------------------
		méthodes de publication:
	communs, actualités, articles, événements
--------------------------------------------------
--------------------------------------------------*/

/* Homepage */

.header-ushuaia-logo {
    position: absolute;
    z-index: 10;
    width: 20vw;
    top: 0;
    left: 0;
    max-width: 360px;
    min-width: 140px;
}
.page-home {
   position: relative;
   .home-slider {
      position: absolute;
      top: 0;
      max-width: 100%;
      .slick-slide img {
         display: block;
         min-height: 800px;
         object-fit: cover;
      }
      @include media-breakpoint-up(md) {
         max-height: 800px;
         overflow: hidden;
         img {
            max-height: 1000px;
            width: 100vw;
            object-fit: cover;
         }
      }
      .slick-dots {
         position: absolute;
         bottom: 24%;
         li button {
            &:before {
               font-size: 3rem;
            }
         }
      }
   }
}
.home__hebergement {
   background: $ushuaiablue;
   background-repeat: no-repeat;
   color: #FFF;
   position: relative;
   padding-top: 5rem;
   text-align: center;
   @include media-breakpoint-up(md) {
      background: url('/images/int/bghebergement.svg') top center;
      background-repeat: no-repeat;
      padding-top: 10rem;
   }
  .home__hebergement-soustitre {
    font-size: 1.75rem;
    display: block;
    font-family: $montserrat;
    margin-top: 0.5rem;
  }
  .home__hebergement-titre {
   font-size: 2rem;
   font-weight: bold;
   display: block;
   margin-top: 1rem;
   @include media-breakpoint-up(md) {
      font-size: 2.8rem;
   }
  }
}
.home__sitetitle {
   position: absolute;
   color: #FFF;
   z-index: 5;
   text-transform: uppercase;
   letter-spacing: 1px;
   width: 1000px;
   left: 50%;
   margin-left: -500px;
   text-shadow: 1px 1px 5px rgba($black, .7);
   display: block;
   text-align: center;
   font-size: 1.7rem;
   @include media-breakpoint-up(md) {
      top: 430px;
      //display: block;
   }
   @include media-breakpoint-down(md) {
      font-size: 16px;
      top: 290px;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
   }
}
.home__hebergement-serarchbox {
    max-width: 800px;
    display: block;
    margin: 0 auto;
    margin-top: -100px;
    @include media-breakpoint-up(md) {
      height: 100px;
    }
}
.home__hebergement-serarchbox {
    .v-application .primary--text {
        color: $ushuaiablue !important;
        caret-color: $ushuaiablue !important;
    }
    .v-application .accent {
        background-color: $ushuaiagold !important;
        border-color: $ushuaiagold !important;
    }
}
.home__section-image {
   &:before {
      display: none;
      @include media-breakpoint-up(md) {
         display: block;
      }
   }
}
.home__section-image--left {
    position: relative;
    margin-bottom: 50px;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $ushuaiablue-opacity;
        border-radius: 440px 30px 30px 30px;
        position: absolute;
        top: 20px;
        left: -20px;
        z-index: -1;
        overflow: hidden;
    }
    .elementPicture {
        border-radius: 440px 30px 30px 30px;
        overflow: hidden;
    }
}
.home__section-image--right {
    position: relative;
    margin-bottom: 50px;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $ushuaiagold-opacity;
        border-radius: 30px 440px 30px 30px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: -1;
        overflow: hidden;
    }
    .elementPicture {
        border-radius: 30px 440px 30px 30px;
        overflow: hidden;
    }
}
.home__section-decouvrir-image {
    position: relative;
    // margin-bottom: 50px;
    // z-index: 1;
    // &:after {
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     background-color: #d4d5ab;
    //     border-radius: 440px 30px 30px 30px;
    //     position: absolute;
    //     top: 20px;
    //     left: -20px;
    //     z-index: -1;
    //     overflow: hidden;
    // }
    .elementPicture {
        border-radius: 440px 30px 30px 30px;
        overflow: hidden;
    }
}
.home__section {
   padding: 3rem 0;
   .table td {padding: 0; }
   @include media-breakpoint-up(md) {
      margin: 5rem 0;
   }
   &.home__activites {
      color: $ushuaiablue;
      position: relative;
      &:before {
         display: none;
         @include media-breakpoint-up(md) {
            content:url('/images/int/deco3.svg');
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
         }
      }
      @media screen and (max-width: 960px) {
          .col-md-6.pl-5 {
            padding-left: 1rem !important;
         }
      }
      

      .home__section-image {
         position: relative;
         display: block;
         &:before {
            content: "\e913";
            font-family: 'icomoon';
            font-size: 25rem;
            bottom: -10rem;
            left: -10rem;
            position: absolute;
         }
      }
      .home__section-link a {
         background: $ushuaiagold;
         color: white;
         padding: 1rem 2rem;
         border-radius: 2rem;
         margin-top: 1rem;
         &:hover {
            background: lighten($ushuaiagold, 2);
            text-decoration: none;
         }
      }
      ul {
         margin-top: 2rem;
      }
      ul li {
         padding-left: 4rem;
         position: relative;
         margin: 0 0 1rem 0;
         &:before {
            font-family: 'icomoon';
            position: absolute;
            margin-left: -3rem;
            font-size: 1.8rem;
            @include media-breakpoint-up(md) {
               margin-left: -4rem;
            }
         }
      }
      .home__section-content td img {
         width: 40px;
      }
   }
   &.home__services {
      color: $ushuaiablue;
      position: relative;
      &:before {
         display: none;
         @include media-breakpoint-up(md) {
            content:url('/images/int/deco4.svg');
            position: absolute;
            right: 0;
            top: -13rem;
            z-index: 1;
         }
      }
      @media screen and (max-width: 960px) {
         .col-md-6.pr-5 {
            padding-right: 0;
         }
      }
      .home__section-image {
         position: relative;
         display: block;
         margin-top: 4rem;
         @include media-breakpoint-up(md) {
            margin-top: auto;
         }
         &:before {
            content: "\e913";
            font-family: 'icomoon';
            font-size: 25rem;
            bottom: -10rem;
            right: -10rem;
            position: absolute;
         }
      }
      .home__section-link a {
         background: $ushuaiagold;
         color: white;
         padding: 1rem 2rem;
         border-radius: 2rem;
         margin-top: 1rem;
         &:hover {
            background: lighten($ushuaiagold, 2);
            text-decoration: none;
         }
      }
      ul {
         margin-top: 2rem;
      }
      ul li {
         padding-left: 4rem;
         position: relative;
         margin: 0 0 1rem 0;
         &:before {
            font-family: 'icomoon';
            position: absolute;
            margin-left: -3rem;
            font-size: 1.8rem;
            @include media-breakpoint-up(md) {
               margin-left: -4rem;
            }
         }
         /*Pain*/
         &:first-child {
            &:before {
               content: "\e92a";
            }
         }
         /*Glace*/
         &:nth-child(2) {
            &:before {
               content: "\e922";
            }
         }
         /*Snack*/
         &:nth-child(3) {
            &:before {
               content: "\e924";
            }
         }
         /*Wifi*/
         &:nth-child(4) {
            &:before {
               content: "\e936";
            }
         }
         /*vélo*/
         &:nth-child(5) {
            &:before {
               content: "\e935";
            }
         }
         /*Food truck*/
         &:nth-child(6) {
            &:before {
               content: "\e921";
            }
         }
      }
   }
   &-toptitle {
      font-family: $montserrat;
      font-size: 1.5rem;
      position: relative;
      &:before {
         content: "\e931";
         font-family: 'icomoon';
         position: absolute;
         top:-2rem;
         opacity: .3;
         font-size: .9rem;
      }
   }
   p {
       margin-top: 1rem;
      color:#333;
      &:first-child{color: inherit;}
      /*
      strong {
         font-size: 3.3rem;
         line-height: 3rem;
      }
      */
   }
   h2 {
       font-weight: bold;
   }
   ul {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      li {
         list-style: none;
         @include media-breakpoint-up(md) {
            width: 50%;
         }
         strong {
            font-size: 1rem;
         }
      }
   }
}

.home__section-decouvrir {
   background: $ushuaiablue;
   color: #FFF;
   position: relative;
   padding: 5rem 0 10rem 0;
   margin-top: 5rem;
   &:before {
      display: none;
      @include media-breakpoint-up(md) {
         content:url('/images/int/deco5.svg');
         position: absolute;
         right: -8rem;
         bottom: 5rem;
         z-index: 1;
         transform: rotate(8deg);
      }
   }
   .container {
      z-index: 1;
      @include media-breakpoint-up(md) {
         &:after {
            position: absolute;
            content: "";
            width: 2000px;
            left: 50%;
            margin-left: -1000px;
            height: 200px;
            background: #fff;
            border-radius: 50%;
            bottom: -100px;
            z-index: 0;
         }
      }
      h2 {
            font-size: 3.3rem;
            text-transform: capitalize;
            font-weight: bold;
         }
   }
   .home__section-decouvrir-image {
      position: relative;
      display: block;
      margin-top: -10rem;
      @include media-breakpoint-up(md){
         margin-left: -4rem;
      }
      &:before {
         display: none;
         @include media-breakpoint-up(md) {
            content: "\e913";
            font-family: 'icomoon';
            font-size: 25rem;
            bottom: -8rem;
            right: -2rem;
            position: absolute;
            opacity: .2;
         }
      }
   }
   .home__section-link a {
      border:4px solid #FFF;
      color: white;
      padding: 1rem 2rem;
      border-radius: 2rem;
      margin-top: 1rem;
      display: inline-block;
      transition: .3s;
      &:hover {
         text-decoration: none;
         background: #FFF;
         color: $ushuaiablue;
      }
   }
   .icon-decosignature {
      font-size: 14rem;
      position: absolute;
      left: 53%;
      bottom: 1.5rem;
      opacity: .1;
      display: none;
      @include media-breakpoint-up(md) {
         display: block;
      }
   }
}

.home__actus {
   strong {
      font-size: 3.3rem;
      line-height: 3rem;
   }
   .home__section-toptitle {
      color: $ushuaiablue;
   }
   .card {
      border:none;
      .box-highlight-posts--img {
         img {
            width: 100%;
            max-height: 330px;
            object-fit: cover;
         }
      }
      .card-body {
         padding: 1.25rem 0;
      }
      .box-highlight-posts--date {display: none;}
      .card-title {
         font-weight: bold;
         font-size: 1.3rem;
      }
      .card-footer {
         background: #FFF;
         border:none;
         padding: 0;
         margin-top: 1rem;
         a {
            border: 2px solid;
            color: #000;
            border-radius: 3rem;
            padding: 1rem 3rem;
            transition: .3s;
            &:hover {
               text-decoration: none;
               background: #000;
               color: #FFF;
            }
         }
      }
   }
}
.home__actus-linkall a {
   color: #FFF;
   background: $ushuaiablue;
   position: relative;
   border-radius: 3rem;
   padding: 1rem 3rem;
   border: 4px solid $ushuaiablue;
   -webkit-transition: .3s;
   -o-transition: .3s;
   transition: .3s;
   margin: 0 auto;
   display: block;
   width: fit-content;
   &:hover {
      color: $ushuaiablue;
      background: #FFF;
      text-decoration: none;
   }
}

.home_prixmalin {
   margin-top: 5rem;
   overflow: hidden;
   @include media-breakpoint-up(md) {
      margin-top: auto;
   }
   strong {
      font-size: 3.3rem;
      line-height: 3rem;
      color: $ushuaiagold;
   }
   .home__section-toptitle {
      color: $ushuaiagold;
   }
   .box-highlight {
      position: relative;
      background: $ushuaiagold;
      color: #FFF;
      text-align: center;
      border-radius: 30px;
      max-height: 600px;
      padding-top: 10px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      &:before {
         content:url('/images/int/decoprixmalin.svg');
         position: absolute;
         left:0;
         top:20%;
         width: 100%;
      }
      strong {
         color: white;
      }
      .box-highlight--link {
         position: relative;
         background: #FFF;
         border-radius: 3rem;
         color: $ushuaiagold;
         padding: 1rem 3rem;
         border: 4px solid $ushuaiagold;
         transition: .3s;
         &:hover {
            background: $ushuaiagold;
            border: 4px solid #FFF;
            text-decoration: none;
            color: #FFF;
         }
      }
   }
}

/* Page annuaire */
.catalog__serchbox {
   // position: absolute;
   bottom: 50px;
   z-index: 9;
   // width: 93%;
   position: relative;
   width: 100%;
  @include media-breakpoint-up(md) {
      display: block;
      // width: 80%;
      // margin-left: 10%;
  }
}
/*
.catalog__serchbox {display: none;}
 body.directoryEntriesList, body.directoryEntryDetail  {
    .catalog__serchbox {
      position: absolute;
      bottom: 30px;
      width: 80%;
      margin-left: 10%;
      @include media-breakpoint-up(md) {
         display: block;
      }
    }
 }
 */

 /* Annuaire détail */
.hebergement__photosslider, .cardhebergement__photos {
   height: 100%;
   img {
      height: 405px;
      width: 100%;
      object-fit: cover;
   }
   a.slick-slide:hover {
       cursor: zoom-in;
   }
   .slick-prev, .slick-next {
      z-index: 5;
      background: #FFF;
      border-radius: 50%;
      top:75%;
      height: 30px;
      width: 30px;
      &:before {
         color: $ushuaiablue;
         font-size: .9rem;
      }
      &:hover {
         background: $ushuaiablue;
         &:before {
            color: #FFF;
         }
      }
   }
   .slick-prev {
      left: 40%;
   }
   .slick-next {
      right: 40%;
   }
}
.fancybox-slide--complete:hover {
    cursor: zoom-out;
}
.hebergement__title {
   color: $olive;
   font-size: 2rem;
   line-height: 2rem;
   position: relative;
   strong {
      font-size: 2.8rem;
   }
   &:before {
      content: "\e931";
      font-family: icomoon;
      position: absolute;
      top:-30px;
      opacity: .5;
      font-size: 1rem;
   }
}
.hebergement__tarif {
   background: $ushuaiablue;
   color: #FFF;
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   padding: .5rem 1rem;
   margin: 2rem 0;
   align-items: center;
   .hebergement__tarifs {
      border-left: 1px solid rgba($white, .2);
      padding: 0 .5rem;
      font-size: 1.5rem;
      font-weight: bold;
      flex: 3;
      span {
         font-weight: normal;
         font-size: 1rem;
      }
   }
   .hebergement__tarif-type {
      padding: 0 .5rem;
      text-align: center;
      flex: 3;
   }
}

.hebergement__infos {
   span {
      width: 50px;
      font-size: 1.3rem;
      text-align: center;
   }
   .hebergement__infos-pers {
      margin: 1rem 0;
   }
   .hebergement__infos-pers  span {
      font-size: 1.7rem;
   }
}
.reservation__title {
   color: $orange;
   font-size: 2rem;
   margin-bottom: 1rem;
   padding: 1rem 0;
}

/* Annuaire découvrir */
.box__decouvrir {
   height: 300px;
   a {
      position: relative;
      display: block;
      height: 100%;
      background: #000;
      border-radius: 10px;
      .box__decouvrir-title {
         color: #FFF;
         text-shadow: 0 2px 5px rgba($black, .7);
      }
      &:hover {
          img {
              opacity: .4;
          }
        .box__decouvrir-title {
            color: #FFF;
            text-shadow: 0 2px 5px rgba($black, .7);
        }
        .box__decouvrir-details {
            display: block;
        }
      }
   }
   img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .6;
      transition: .3s;
   }
   overflow: hidden;
}
.box__decouvrir-infos {
    position: absolute;
    font-size: 1.5rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    flex-direction: column;
    padding: 1.5rem;
}
.box__decouvrir-title {
   font-weight: bold;
}
.box__decouvrir-details {
    font-size: .9rem;
    font-weight: normal;
    color: #FFF;
    display: none;
}
/* Liste hebegements */
.cardhebergement__box {
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   border-radius: 10px;
   box-shadow: 0 0 15px rgba($black, .1);
   margin: 1rem auto;
   overflow: hidden;
   transition: .3s;
   width: 100%;
   position: relative;
   &:hover {
      text-decoration: none;
      box-shadow: 0 0 15px rgba($black, .2);
   }
   @include media-breakpoint-up(md) {
      flex-direction: row;
      height: 317px;
   }
   .cardhebergement__photos  {
      width: 350px;
      height: 317px;
      display: block;
      flex: 1;
      @include media-breakpoint-down(sm) {
         width: 100%;
      }
      img {
         height: 317px;
         object-fit: cover;
      }
   }
}
.cardhebergement__description {
   font-size: .9rem;
}
.cardhebergement__link {
   color: #333;
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   flex: 2;
   justify-content: space-between;
   @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
   }
   &:hover {
      text-decoration: none;
      color: inherit;
   }
   .cardhebergement__content {
      padding: 1rem;
      max-width: 590px;
      .cardhebergement__pers {
         font-weight: bold;
         margin: 1rem 0;
      }
   }
   .cardhebergement__title {
      font-size: 2rem;
      font-weight: bold;
      color: $ushuaiablue;
   }
   .cardhebergement__infos {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      @include media-breakpoint-down(sm) {
         flex-direction: row;
         flex-wrap: wrap;
      }
      .cardhebergement__typeprice {
         background: $ushuaiablue;
         color:#FFF;
         -webkit-border-top-left-radius: 20px;
         -webkit-border-bottom-left-radius: 20px;
         -moz-border-radius-topleft: 20px;
         -moz-border-radius-bottomleft: 20px;
         border-top-left-radius: 20px;
         border-bottom-left-radius: 20px;
         padding: .5rem 1rem;
         font-size: .9rem;
         @include media-breakpoint-down(sm) {
            text-align: right;
         }
      }
      .cardhebergement__price--off {
         margin-top: 1rem;
         margin-bottom: 1rem;
      }
      .cardhebergement__price {
         padding: 0 .5rem;
         text-align: right;
         font-size: .9rem;
         @include media-breakpoint-down(sm) {
            width: 100%;
         }
         span {
            font-weight: bold;
            font-size: 1.8rem;
            display: block;
         }
      }
   }
}
.autreshebergements {
   display: none;
}
body.directoryEntriesList, body.hebergement  {
   .autreshebergements {
     display: block;
     overflow: hidden;
      background: $ushuaiablue;
      color:#FFF;
      padding-top: 7em;
      position: relative;
      span {
         font-weight: bold;
         position: relative;
         color:#FFF;
         display: block;
         text-align: center;
         font-size: 2rem;
         &:before {
            content: "\e931";
            position: absolute;
            font-family: "icomoon";
            left: 50%;
            top: -2rem;
            font-size: 1rem;
            margin-left: -28px;
            opacity: .5;
         }
      }
      &:before {
         content: url('/images/bgdefaut2.svg');
         position: absolute;
         top:-10px;
         left: 50%;
         margin-left: -1192px;
         transform: scaleY(-1);
      }
   }
}

/* Page contact */
.form-entete {
   color:$ushuaiablue;
   font-weight: bold;
   font-size: 1.1rem;
   margin-bottom: 2rem;
   display: block;
   &:before {
      content: "\e931";;
      opacity: .5;
      font-family: 'icomoon';
      margin-top: -35px;
      position: absolute;
   }
   &:after {
       content: "\e934";
       font-family: 'icomoon';
       font-size: .5rem;
   }
}
.infos_camping {
   margin: 4rem 0;
   &-adresse, &-email {
      color: $ushuaiablue;
      font-weight: bold;
   }
   span {position: relative;}
   span.icon {
      position: absolute;
      margin-left: -1.8rem;
   }
   &-adresse {
      span.icon {
         font-size: 1.5rem;
      }
   }
   &-logo {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        margin: 2rem 0;

        > * {
            flex: 40%;
            max-width: 40%;
        }
   }
}

.contact_image {
   position: relative;
   @include media-breakpoint-up(md) {
      &:before {
         content: "\e913";
         font-family: icomoon;
         font-size: 25rem;
         bottom: -10rem;
         left: -10rem;
         position: absolute;
         color: $ushuaiagold;
      }
   }
}

/* Actualités */
.actualites h1 {
   text-transform: capitalize;
}
.post-item {
   height: 100%;
   border-radius: 10px;
   overflow: hidden;
   a {
      transition: .3s;
      h2 {
         font-size: 1.5rem;
         color: #222;
      }
      &:hover {
         text-decoration: none;
      }
   }
}
/* Footer */
.box-highlight--desc, .box-highlight--title {
   text-align: center;
   @include media-breakpoint-up(md) {
      text-align: inherit;
   }
}
.box-highlight--title {
   font-weight: bold;
}
.adresse_footer {
   a {
      &.adresse_footer-adresse {
         background: #FFF;
         color: #000;
         border-radius: 30px;
         padding: 10px 20px;
         display: inline-block;
         margin-bottom: .5rem;
         transition: .3s;
         &:hover {
            background: $ushuaiagold;
            color: #000;
            text-decoration: none;
         }
      }
      &.adresse_footer-localisation {
         background: $ushuaiagold;
         color: $black;
         // border: 2px solid #444;
         border-radius: 30px;
         padding: 10px 20px;
         transition: .3s;
         &:hover {
            background: #FFF;
            // color: $black;
            text-decoration: none;
            // border: 2px solid #FFF;
         }
      }
   }
}
.footer_suiveznous {
   a {
      font-size: 1.8rem;
      color: #FFF;
      padding: 0 0.5rem;
   }
   .box-highlight--desc p {
      &:first-child {
         display: -webkit-flex;
         display: -ms-flex;
         display: flex;
         justify-content: space-around;
         @include media-breakpoint-up(md) {
            width: 150px;
         }
      }
   }
}
/* media (bootstrap) : présentation famille, header news detail, header event detail, structure bloc&image emajine */
.media{
    flex-wrap: wrap;
}
// .media-body{}
@include media-breakpoint-up(sm) {
    .media{
        flex-wrap: nowrap;
    }
    .media-img{
        max-width: 25%;
    }
}

/*card (short-product, article list, news list, event-list, social wall, ...)*/
// .card{}
// .card-body{}
// .card-link{}
// .card-title{}
// .card-text{}

/* commons (multiple) */
// .mu {}
// .mu--title {}
// .mu--list {}

// .mu--item {}
// .mu--item-link {}
// .mu--item-img {}
// .mu--item-container {}
// .mu--item-header {}
// .mu--item-title {}
// .mu--item-infos {}
// .mu--item-date {}
// .mu--item-desc {}

// .mu--archives-link {}

/* news (/publication_methods/news/news.html) */
// .news {}
// .news--title {}
// .news--list {}

// .news--item {}
// .news--item-link{}
// .news--item-img {}
// .news--item-container {}
// .news--item-header {}
// .news--item-title {}
// .news--item-infos {}
// .news--item-date {}
// .news--item-desc {}

// .news--archives-link {}

/* articles (/publication_methods/articles/multi_articles.html) */
// .articles {}
// .articles--title {}
// .articles--list {}

// .articles--item {}
// .articles--item-link{}
// .articles--item-img {}
// .articles--item-container {}
// .articles--item-header {}
// .articles--item-title {}
// .articles--item-infos {}
// .articles--item-desc {}

// .articles--archives-lin {}

/* events (/events/events.html) */
// .events {}
// .events--title {}
// .events--list {}

// .events--search {}
// .events--search-label {}
// .events--search-date {}
// .events--search-period-list {}
// .events--search-period-item {}
// .events--search-period {}
// .events--search-type {}
// .events--search-keywords {}
// .events--search-submit {}
// .events--period-link{}
// .events--period-prev {}
// .events--period-today {}
// .events--period-next {}
// .events--type-recall {}
// .events--keyword-recall {}

// .events--item-link {}
// .events--item-img {}
// .events--item-container {}
// .events--item-header {}
// .events--item-title {}
// .events--item-infos {}
// .events--item-date {}
// .events--item-type {}
// .events--item-desc {}

/* Comments (/community/comments/list_comments.html) */
/* Attention les commentaires sont utilisé dans plusieurs modules.
vous retrouverez les classes spécifique aux comments product dans la fiche de style catalog.less par exemple */
// .comments {}
// .comments--title {}
// .comments--empty {}
// .comments--nb {}
// .comments--average {}
// .comments--moderation {}
// .comments--confirm {}
// .comments--list {}
// .comments--list-link{}
// .comments--form-link {}

// .comment {}
// .comment--title {}
// .comment--note {}
// .comment--txt {}
// .comment--author {}
// .comment--date {}
// .comment--site {}

/* Comments form (/community/comments/form_add_comment.html ) */
// .comments--form {}
// .comments--legend {}
// .comments--form-error {} /* Attention correspond a la notification d'erreur unique dans le template commentaire de produit */


/** RESPONSIVE **/

// Small devices (landscape phones, 34em and up)
@include media-breakpoint-up(sm) {
}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {
}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {
}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {
}
