/*	--------------------------------------------------
	--------------------------------------------------
		Gestion des fonts.
		Possibilité de créer une mixin par font

	--------------------------------------------------
	--------------------------------------------------*/


// @import url("https://fonts.googleapis.com/css2?family=Cookie&family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap&family=Poppins:wght@400;700&display=swap");

$font-path:"fonts/";

/** ICOMOON **/
$font-icon-folder:"icomoon/";
$font-icon-name:"icomoon";
$font-icon-svg-id:"icomoon";

@font-face {
	font-family: $font-icon-name;
		src:url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.eot');
		src:url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.eot') format('embedded-opentype'),
			url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.ttf') format('truetype'),
			url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.woff') format('woff'),
			url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.svg##{$font-icon-svg-id}') format('svg');
		font-weight: normal;
		font-style: normal;
    font-display: swap;
}

[class^="icon-"], [class*=" icon-"], .icomoon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  display: inline-block;
  vertical-align: middle;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-zephyr:before {
  content: "\e905";
}
.icon-cercle_vague_ok:before {
  content: "\e913";
}
.icon-cercle_vague:before {
  content: "\e914";
  color: #857f00;
}
.icon-chevron_haut:before {
  content: "\e915";
}
.icon-chevron_d:before {
  content: "\e916";
}
.icon-chevron_g:before {
  content: "\e917";
}
.icon-adresse:before {
  content: "\e918";
}
.icon-aire_jeux:before {
  content: "\e919";
}
.icon-aquagym:before {
  content: "\e91a";
}
.icon-arrow:before {
  content: "\e91b";
}
.icon-balade:before {
  content: "\e91c";
}
.icon-bed:before {
  content: "\e91d";
}
.icon-cercle_vague1:before {
  content: "\e91e";
}
.icon-decosignature:before {
  content: "\e91f";
}
.icon-Fb:before {
  content: "\e920";
}
.icon-foodtruck:before {
  content: "\e921";
}
.icon-glace:before {
  content: "\e922";
}
.icon-Insta:before {
  content: "\e923";
}
.icon-laverie:before {
  content: "\e924";
}
.icon-logo_blanc:before {
  content: "\e925";
}
.icon-loupe:before {
  content: "\e926";
}
.icon-mail1:before {
  content: "\e927";
}
.icon-marcheaqua:before {
  content: "\e928";
}
.icon-multisport:before {
  content: "\e929";
}
.icon-pain:before {
  content: "\e92a";
}
.icon-people:before {
  content: "\e92b";
}
.icon-petanque:before {
  content: "\e92c";
}
.icon-picto_citation:before {
  content: "\e92d";
}
.icon-player:before {
  content: "\e92e";
}
.icon-pool:before {
  content: "\e92f";
}
.icon-star:before {
  content: "\e930";
}
.icon-stars:before {
  content: "\e931";
}
.icon-superficie:before {
  content: "\e932";
}
.icon-Tripadv:before {
  content: "\e933";
}
.icon-vague_3:before {
  content: "\e934";
}
.icon-velo:before {
  content: "\e935";
}
.icon-wifi:before {
  content: "\e936";
}
.icon-smartphone:before {
  content: "\e937";
}
.icon-locked:before {
  content: "\e90d";
}
.icon-secure:before {
  content: "\e90d";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-pen:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e209";
}
.icon-done:before {
  content: "\e28a";
}
.icon-mail:before {
  content: "\e601";
}
.icon-remove-user:before {
  content: "\e90e";
}
.icon-download:before {
  content: "\e90f";
}
.icon-megaphone:before {
  content: "\e910";
}
.icon-chevron-small-left:before {
  content: "\e607";
}
.icon-chevron-small-right:before {
  content: "\e608";
}
.icon-chevron-thin-left:before {
  content: "\e609";
}
.icon-chevron-thin-right:before {
  content: "\e60a";
}
.icon-location-pin:before {
  content: "\e60b";
}
.icon-twitter:before {
  content: "\e60c";
}
.icon-bin:before {
  content: "\e912";
}
.icon-trashcan:before {
  content: "\e912";
}
.icon-remove:before {
  content: "\e912";
}
.icon-delete:before {
  content: "\e912";
}
.icon-recycle:before {
  content: "\e912";
}
.icon-dispose:before {
  content: "\e912";
}
.icon-folder-plus:before {
  content: "\e911";
}
.icon-directory:before {
  content: "\e911";
}
.icon-folder-add:before {
  content: "\e911";
}
.icon-cog:before {
  content: "\e90b";
}
.icon-gear:before {
  content: "\e90b";
}
.icon-settings:before {
  content: "\e90b";
}
.icon-generate:before {
  content: "\e90b";
}
.icon-make:before {
  content: "\e90b";
}
.icon-options:before {
  content: "\e90b";
}
.icon-switch:before {
  content: "\e90c";
}
.icon-power:before {
  content: "\e90c";
}
.icon-linkedin:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-share:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e602";
}
.icon-arrow-left:before {
  content: "\e603";
}
.icon-user:before {
  content: "\e604";
}
.icon-printer:before {
  content: "\e605";
}
.icon-search:before {
  content: "\e90a";
}
.icon-youtube:before {
  content: "\e906";
}
.icon-tripadvisor:before {
  content: "\e900";
}
.icon-pinterest:before {
  content: "\e901";
}
.icon-phone:before {
  content: "\f095";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-chevron-double-small-right:before {
  content: "\e60d";
}
.icon-chevron-double-small-left:before {
  content: "\e60e";
}
.icon-chevron-double-thin-left:before {
  content: "\e60f";
}
.icon-chevron-double-thin-right:before {
  content: "\e610";
}
.icon-google:before {
  content: "\e600";
}
.icon-shopping-cart:before {
  content: "\e907";
}
