/*	--------------------------------------------------
	--------------------------------------------------
		Widgets :
	propriétés générales, texte, actu, rss,
	banniere, calendrier, raccourcis, météo, sondage,
	recommander le site, newsletter, publicité

--------------------------------------------------
--------------------------------------------------*/
body.page-defaut {
   .header--boxes {
      background: #FFF;
      border-radius:60px;
      padding: 0 .5rem 0 2rem;
      margin-top: 1rem;
      margin-right:27%;
      float: right;
      justify-content: center;
      z-index: 1031;
      position: relative;
      @media (min-width: 1650px) {
         margin-right: 15%;
      }
   }
}
.header--boxes {
   display: none;
   @include media-breakpoint-up(md) {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
   }
  .box {
     padding: 1rem;
     padding-left: 2rem;
    p {
      margin: 0;
    }
    a {
      color: #000;
      &:hover {
         text-decoration: none;
      }
    }
    &:first-child {
      a {
         position: relative;
        &:before {
         content: '\e927';
         font-family: 'icomoon';
         left: -2rem;
         top: 50%;
         margin-top: -20%;
         position: absolute;
        }
      }
    }
    &:nth-child(2) {
      a {
        position: relative;
        &:before {
          content: '\e937';
          font-family: 'icomoon';
          font-family: icomoon;
          left: -1.4rem;
          top: 50%;
          margin-top: -15%;
          position: absolute;
          font-size: 1.3rem;
        }
        &:after {
          content: '';
          position: absolute;
          left: -2rem;
          height: 27px;
          width: 1px;
          background: #DDD;
        }
      }
    }
    &:nth-child(3) {
      a {
        position: relative;
        &:before {
          content: '\e902';
          font-family: 'icomoon';
          font-family: icomoon;
          left: -1.6rem;
          top: 50%;
          margin-top: -20%;
          position: absolute;
          font-size: 1.4rem;
        }
        &:after {
          content: '';
          position: absolute;
          left: -2rem;
          height: 27px;
          width: 1px;
          background: #DDD;
        }
      }
    }
  }
}
.box-bonnesraisons {
   margin-top: 5rem;
   position: relative;
   margin-bottom: 5rem;
   @include media-breakpoint-up(md) {
      min-height: 730px;
      margin-bottom: auto;
   }
   color: #FFF;
   .box-bonnesraisons-imgbg {
      width: 100%;
      height: 100%;
      position: absolute;
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   .box-bonnesraisons-content {
      margin-top: 3rem;
      @include media-breakpoint-up(md) {
         margin-top: 10rem;
         ul li {
            font-size: 1.3rem;
         }
      }
      strong {
         font-size: 3rem;
         line-height: 2rem;
      }
      em {
         font-size: 2rem;
         font-style: normal;
      }
      ul {
         padding: 0;
      }
      ul li {
         list-style: none;
         line-height: 2.5rem;
         &:before {
            content:url('/media/plus.svg');
            margin-right: 0.5rem;
         }
      }
   }
   .box-bonnesraisons-avis {
      background: #FFF;
      border-radius: 30px;
      text-align: center;
      padding: 2rem 1rem;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .v-card {border:0!important;}
      .home__section-toptitle {
         color:#000;
         &:before {
            content: "\e931";
            color:$olive;
            font-family: icomoon;
            position: absolute;
            top: -1.5rem;
            left:50%;
            margin-left: -25px;
            opacity: .3;
            font-size: .9rem;
         }
      }
   }
}
body.page-home {
   .header--boxes {
      position: absolute;
      top: 1rem;
      left: 50%;
      margin-left: -144px;
      background: $ushuaiablue;
      border-radius:60px;
      padding: 0 .5rem 0 2rem;
      margin-top: 1rem;
      justify-content: center;
      z-index: 1031;
      a {
         color: #FFF;
      }
      @include media-breakpoint-up(md) {
         left: 20%;
         margin-left: auto;
      }
   }
}
.box-follow-us, .box-concept, .box-reservation {
   display: none;
   @include media-breakpoint-up(md) {
      display: block;
   }
}
.box-reservation {
   @include media-breakpoint-down(sm) {
      display: block;
      .box-highlight--desc{
         top: inherit!important;
         bottom: 4%;
         z-index: 99;
      }
   }
}

/* Alerte infos */
.modal-header {
   border: 0;
}
.modal-titre {
   color: $orange;
   text-align: center;
   width: 100%;
   font-size: 1.7rem;
   &:before {
      content: "\e931";
      font-size: 1rem;
      font-family: icomoon;
      display: block;
   }
}
a.box-highlight--link {
   background: $ushuaiablue;
   color: #FFF;
   border-radius: 20px;
   padding: .5rem 2rem;
   margin: 1rem;
   display: inline-block;
   &:hover {
      text-decoration: none;
      background: darken($ushuaiablue, 10%);
   }
}
/* Suivez nous */
.box-follow-us {
   .box-highlight--desc {
      display: block;
      width: 130px;
      height: 110px;
      background: #FFF;
      padding: 10px 5px;
      text-align: center;
      position: fixed;
      top: 20%;
      margin-top: 250px;
      right: 1%;
      color: $ushuaiablue;
      -webkit-border-radius: 15px;
      -webkit-border-top-right-radius: 0;
      -moz-border-radius: 15px;
      -moz-border-radius-topright: 0;
      border-radius: 15px;
      border-top-right-radius: 0;
      z-index: 5;
      box-shadow: 0 0 10px rgba($black, .2);
      p {margin: 0;line-height: 1rem;}
      a {
         width: 42px;
         height: 42px;
         font-size: 1.3rem;
         display: inline-flex;
         justify-content: center;
         align-items: center;
         color: #FFF;
         border-radius: 50%;
         background: $ushuaiagold;
         margin-bottom: 10px;
         &:hover {
            text-decoration: none;
            background: darken($ushuaiagold, 10);
         }
      }
   }
}

/* Box hebergement */
.box__hebergement-list {
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   justify-content: space-around;
   width: 80%;
   margin: 3% 10%;
   position: relative;
   z-index: 5;
   flex-wrap: wrap;
   @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
   }
   @include media-breakpoint-down(md) {
      flex-wrap: wrap;
   }
   .box__hebergement-item {
      width: 365px;
      height: 270px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      color: #FFF;
      background: #000;
      margin: 1rem 0;
      @include media-breakpoint-up(md) {
         width: 365px;
         height: 470px;
         margin: 0 10px;
      }
      @include media-breakpoint-down(md) {
         margin-bottom: 14px;
      }
      .box-highlight--desc, .box-hebergement--title {
         &:hover {
            cursor: default;
         }
      }
      .box-hebergement--title {
         font-size: 1.75rem;
         position: relative;
         font-weight: bold;
         color: #FFF;
         @include media-breakpoint-up(md) {
            height: 85px;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
         }
      }
      img {
         position: absolute;
         object-fit: cover;
         width: 100%;
         height: 100%;
         top:0;
         left:0;
         transition: .3s;
      }
      a {
         background: $ushuaiagold;
         display: inline-block;
         padding: 0.5rem 2rem;
         border-radius: 2rem;
         text-align: center;
         color:#FFF;
         transition: .3s;
         &:hover {
            text-decoration: none;
            color:#333;
            background: #FFF;
         }
      }
      &:hover {
         background: none;
         img {
            filter: blur(3px);
            z-index: -1;
            opacity: 1;
         }
         .box__hebergement-item--content {
            width: 100%;
            top:0;
            justify-content: center;
            .box-highlight--desc, .box-highlight--link { opacity: 1;}
            .box-highlight--desc {
               display: none;
               @include media-breakpoint-up(md) {
                  display: block;
               }
            }
            &:before {
               content: '';
               background: rgba(0,0,0, .7);
               height: 100%;
               width: 100%;
               display: block;
               position: absolute;
               top:0;
               z-index: -1;
            }
         }
         .box-hebergement--title {
            position: relative;
            &:after {
               content:url("/media/wave.svg");
               position: absolute;
               bottom: -14px;
               left: 50%;
               margin-left: -25px;
            }
         }
      }
      .box__hebergement-item--content {
         display: -webkit-flex;
         display: -ms-flex;
         display: flex;
         flex-direction: column;
         align-items: center;
         position: absolute;
         top:65%;
         transition: .3s;
         padding: 0 2rem;
         height: 100%;
         @include media-breakpoint-up(md) {
            top:75%;
            height: 470px;
         }
         @include media-breakpoint-down(md) {
            top: 0;
            justify-content: center;
            width: 100%;
         }
         &:before {
            content: '';
            background: rgb(49,51,55);
            background: -moz-linear-gradient(180deg, rgba(49,51,55,0) 0%, rgba(0,0,0,1) 100%);
            background: -webkit-linear-gradient(180deg, rgba(49,51,55,0) 0%, rgba(0,0,0,1) 100%);
            background: linear-gradient(180deg, rgba(49,51,55,0) 0%, rgba(0,0,0,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#313337",endColorstr="#000000",GradientType=1);
            height: 200px;
            width: 100%;
            display: block;
            position: absolute;
            top:-20px;
            left:0;
            @include media-breakpoint-down(md) {
               display: none;
            }
         }
         .box-highlight--desc, .box-highlight--link { opacity: 0;}
         .box-highlight--desc{
            @include media-breakpoint-down(md) {
               display: none;
            }
         }
         .box-highlight--link {
            @include media-breakpoint-down(md) {
               opacity: 1;
            }
         }
      }
   }
}

/* Le concept */
.box-concept {
   .box--title {display: none;}
   .box-highlight--desc {
      display: block;
      width: 130px;
      height: 110px;
      background: $ushuaiablue;
      padding: 10px 5px;
      text-align: center;
      position: fixed;
      top: 20%;
      right: 1%;
      color: #FFF;
      margin-top: 125px;
      -webkit-border-radius: 15px;
      -webkit-border-top-right-radius: 0;
      -moz-border-radius: 15px;
      -moz-border-radius-topright: 0;
      border-radius: 15px;
      border-top-right-radius: 0;
      z-index: 5;
      img {width: 42px;margin-bottom: 10px;}
      p {margin: 0;line-height: 1rem;}
      a {
         color: #FFF;
         &:hover {text-decoration: none;}
         font-size: .9rem;
      }
   }
}

/* Le concept */
.box-reservation {
   .box--title {display: none;}
   .box-highlight--desc {
      display: block;
      width:85px;
      height: 70px;
      background: $ushuaiagold;
      padding: 10px 5px;
      text-align: center;
      position: fixed;
      top: 20%;
      right: 1%;
      color: #FFF;
      -webkit-border-radius: 15px;
      -webkit-border-top-right-radius: 0;
      -moz-border-radius: 15px;
      -moz-border-radius-topright: 0;
      border-radius: 15px;
      border-top-right-radius: 0;
      z-index: 5;
      @include media-breakpoint-up(sm) {
         width: 130px;
         height: 110px;
      }
      img {width: 42px;margin-bottom: 10px;}
      p {
        margin: 0;line-height: 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      a {
         color: #FFF;
         &:hover {text-decoration: none;}
         font-size: .8rem;
         position: relative;
         display: -webkit-flex;
         display: -ms-flex;
         display: flex;
         flex-direction: column;
         align-items: center;
         &:before {
           content: '\e902';
           font-family: 'icomoon';
           font-size: 1.7rem;
           background: #FFF;
           padding: 0.5rem;
           border-radius: 50%;
           line-height: 1.7rem;
           color: $ushuaiagold;
         }
         @include media-breakpoint-up(sm) {
            font-size: .9rem;
         }
      }
   }
}

/* Gmap carte footer */
.box-gmap {
   position: relative;
   .box-highlight--link {
      position: absolute;
      left: 50%;
      margin-left: -156px;
      top:50%;
      margin-top: -22px;
      background: $ushuaiablue;
      color: #FFF;
      border-radius: 30px;
      padding: 10px 20px;
      display: inline-block;
      margin-bottom: 0.5rem;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      &:hover {
        background: darken($ushuaiablue, 10%);
         color: #FFF;
         text-decoration: none;
      }
      &:before {
         content:'\e918';
         font-family: 'icomoon';
         margin: 0 .5rem;
      }
   }
}
/* Logos footer */
.box-logos-footer {
   background: #FFF;
   p {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
   }
}
/* commons */
// .box {}
// .box--title {}

//no masque les titres des widget avec la classe "no-title"
.no-title .box--title{display: none}

/* text (/boxes/text/box_text.html) */
// .box-txt {}

/* shortcuts header (/boxes/shortcut/header_shortcut.html) */
// .box-header-links {}
// .box-header-links--list {}
// .box-header-links--item {}
// .box-header-links--link{}

/* shortcuts aside (/boxes/shortcut/tools_shortcut.html) */
// .box-aside-links {}
// .box-aside-links--list {}
// .box-aside-links--item {}
// .box-aside-links--link {}

/* shortcuts footer (/boxes/shortcut/footer_shortcut.html) */
// .box-footer-links {}
// .box-footer-links--list {}
// .box-footer-links--item {}
// .box-footer-links--link {}

/* last elements (/boxes/last_elements/last_elements.html) */
// .box-last-elts {}
// .box-last-elts--list {}
// .box-last-elts--item {}
// .box-last-elts--article {}
// .box-last-elts--title {}
// .box-last-elts--date {}
// .box-last-elts--img {}
// .box-last-elts--desc {}
// .box-last-elts--link{}

/* newsletter (/newsletters/box_newsletters.html) */
// .box-nl {}
// .box-nl--desc {}
// .box-nl--form {}
// .box-nl--label {}
// .box-nl--input {}
// .box-nl--submit {}


/* search (/search/box_search.html) */
// .box-search {}
// .box-search--form {}
// .box-search--label {}
// .box-search--input {}
// .box-search--submit {}
// .box-search--advanced {}

/* box-social (/z-templates/social.tmpl.html) */
// .box-social {}
.box-social--list{margin-bottom: 0;}
.box-social--item {
    display: inline-block;
    vertical-align: middle;
}
.box-social--link {
    background: #000;
	color: #fff;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	text-align: center;
	padding-top: 7px;
	display: block;
	text-decoration: none;
}
// .box-social--facebook {}
// .box-social--twitter {}
// .box-social--google {}

// Fix
.cardhebergement__link .cardhebergement__infos {
   justify-content: flex-start;
   padding-top: 1rem;
}
