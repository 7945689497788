/**
*
* Navigation
* Menu, breadcrumb, pagination, pager, ...
*
**/
.dropdown-menu.show{width: 100% !important;}
/* gestion du tactile quand le menu repasse en desktop */
@include media-breakpoint-up(md) {
  .navbar-nav .nav-item:hover > .dropdown-menu, .navbar-nav .nav-item:focus > .dropdown-menu{ display: block;  }
  //à tester pour ios (bootstrap gère ca?)
  .no-touch .navbar-nav li:hover > ul.dropdown-menu{ display: none; }
  .no-touch .navbar-nav li.open > ul.dropdown-menu { display: block;}
   .fixed-top {
      @include media-breakpoint-up(md) {
         right: 20%;
      }
   }
//dropdown
.navbar-nav .dropdown-menu{margin-top:0;}
//niveau 3 positionné à droite
  .dropdown-menu .dropdown-menu{
     top: 0;
     left: 100%;
  }
}
@include media-breakpoint-down(sm) {
   .nav-item .dropdown-menu.open {display: block;}
}
.fixed-top{
   @include media-breakpoint-down(sm) {
      left: inherit!important;
   }
}
.navbar-collapse {
   justify-content: flex-end;
}
.navbar-dark {
  .navbar-toggler {
   background: $ushuaiablue;
   border: 0;
   padding: 0;
   padding-bottom: 1rem;
   outline: none;
   z-index: 3;
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
    height: 65px;
    width: 75px;
    @include media-breakpoint-up(sm) {
      width: 80px;
      height: 90px;
    }
   .btn-close {
      opacity: 1;
      transition: 1s;
      height: 0;
   }
   .btn-open {
      display: none;
   }
   &.collapsed {
      .btn-open {
         display: block;
      }
      .btn-close {
         opacity: 0;
      }
   }
  }
  span {
   color: #FFF;
   letter-spacing: .05rem;
   font-size: 0.8rem;
   bottom: 0.5rem;
    @include media-breakpoint-up(sm) {
      font-size: 0.9rem;
    }
  }
  .line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
  }
  .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
  }
  .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
  }
  .opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
  }
  .opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
  }
  .opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
  }
}
#menu-main {
  margin-top: 90px;
  padding: 1rem;
   @include media-breakpoint-up(md) {
      padding: 0 0 0 100px;
      margin-top: 150px;
      position: relative;
      z-index: 10;
   }
   > li.nav-item {
      font-size: 1.5rem;
      font-weight: bold;
      opacity: .6;
      transition: .5s;
      @include media-breakpoint-up(md) {
         font-size: 1.8rem;
      }
      &.active {
         opacity: 1;
      }
      &:hover {
         opacity: 1;
      }
   }
   .dropdown-menu a {
      font-weight: normal;
      font-size: 1.4rem;
   }
}
.navbar-collapse {
   &.show {
     animation: .1s slidein;
     animation-fill-mode: forwards;
     background: $ushuaiablue;
     color: #FFF;
     height: 100vh;
     top: 0;
     right: 0;
     z-index: 10;
     width: 100%;
     position: fixed; 
     overflow-y: scroll;
     @include media-breakpoint-up(md) {
        width: 50vw;
     }
     .nav-item  a {
        color: #FFF;
     }
     &:after {
        display: none;
        content: url('/media/deco2.svg');
        position: absolute;
        bottom: 0;
        right: 30%;
        transform: rotate(-25deg) scale(1.5);
        z-index: 9;
        @include media-breakpoint-up(md) {
           display: block;
        }
     }
   }
}
@keyframes slidein {
  from { right:-50vw; }
  to   { right:0; }
}
.collapsing {
-webkit-transition: none;
transition: none;
display: none;
}
li.dropdown > a {
   cursor: default;
}
.navbar-light .dropdown {

    &:hover, &:focus {

        > a {
            color: $navbar-light-hover-color;
        }
    }
}

/*=======================================
=            Animation Menu             =
=======================================*/

/* .dropdown{
    &.open, &:hover, &:focus{
        & > .dropdown-menu {
          -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
        }
    }
}

.dropdown-menu {
  transform-origin: top;
  animation-fill-mode: forwards;
  transform: scale(1, 0);
  display: block;
  transition: all 0.2s ease-out;
}
 */


/*=====  End of Animation Menu ======*/
