/*	--------------------------------------------------
	--------------------------------------------------
		Agencement des blocs structurants :
	container, header, content, sidebar, footer
--------------------------------------------------
--------------------------------------------------*/

body {
  overflow-x: hidden;
}
html {
   scroll-behavior: smooth;
}
.header {
   position: relative;
  .bg-header {
    position: absolute;
    top:0;
    background: url('/media/r_foret.jpg');
    background-size: cover;
    height: 400px;
    width: 100%;
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
  .container {
    position: relative;
    min-height: 400px;
  }
  &:after {
     @include media-breakpoint-up(md) {
        content: url('/images/bgdefaut2.svg');
        position: absolute;
        top: 310px;
        left: 50%;
        margin-left: -1192px;
     }
   }
}
.page-defaut {
   .logo {
     &.navbar-brand {
         position: absolute;
         left:0;
         top: 0;
         -webkit-filter: drop-shadow( 3px 3px 7px rgba(0, 0, 0, .4));
         text-align: center;
         width: 100%;;
         filter: drop-shadow( 3px 3px 7px rgba(0, 0, 0, .4));
         img {
            width: 177px;
         }
         @include media-breakpoint-up(md) {
            text-align: inherit;
            top: 3rem;
            img {
               width: 277px;
            }
         }
     }
   }
}
.page-home {
   .header {
      @include media-breakpoint-up(md) {
         min-height: 600px;
      }
   }
   .logo {
      &.navbar-brand {
         position: absolute;
         display: block;
         top: 20%;
         z-index: 5;
         -webkit-filter: drop-shadow( 3px 3px 7px rgba(0, 0, 0, .7));
         filter: drop-shadow( 3px 3px 7px rgba(0, 0, 0, .7));
         @include media-breakpoint-up(md) {
            left: 50%;
            top: 20%;
            margin-left: -288px;
         }
         @include media-breakpoint-down(md) {
            top: 7%;
         }
         img {
            width: 576px;
         }
      }
   }
}

.content {
   padding-bottom: 4rem;
}

.main {
    img {
        border-radius: 10px;
    }
}
// .right-bar {}

.footer {
   background: $ushuaiablue;
   color: #FFF;
   padding-top: 4rem;
   padding-bottom: 1rem;

   a {
    color: $ushuaiagold;
   }
}