/**
*
* Composants spécifique au CMS saytup
*
**/
.page-element{
	margin-bottom: 15px;
}


/* ---------------------------------------- */
/*     Modules                              */
/* ---------------------------------------- */
.intext_left {
	float: left;
	margin: 0 40px 40px 0;
}
.intext_right {
	float: right;
	margin: 0 40px 40px;
}

.above_center, .below_center { text-align: center; }
.above_right, .below_right   { text-align: right; }
.above_left, .below_left     { text-align: left; }


/* ---------------------------------------- */
/*     gallery                              */
/* ---------------------------------------- */
.gallery-img{
	height: 190px;
	overflow: hidden;
	margin-bottom: 30px;
	img{
		object-fit: cover;
		width: 100%;
		height: 100%;
		font-family: "object-fit:cover;"
	}
}

/*=============================================
=            		CALENDAR     		     =
=============================================*/
.calendar {
	margin-bottom: 0;
	border: 1px solid #ccc;

	caption {
		padding: 5px 0;
		color: #fff;
		background: #428bca;
	}

	thead{
		background: #eee;
	}

	&.table{
		& > tbody{
			& > tr {
				& > td{
					height: 40px;
					padding: 0;
					line-height: 0;
					background: #fff;
					text-align: center;
					vertical-align: middle;

					a{
						display: block;
						height: 40px;
						padding: 0 8px;
						line-height: 40px;

						&:hover{
							background-color: #d9edf7;
						}
					}

					&.today{
						color: #fff;

						a{
							color: #fff;
							background: #428bca;
						}
					}

					&.no_day{
						background: #f6f6f6;
					}
				}
			}
		}
	}
}


/*=====  End of CALENDAR  ======*/

/*================================
=            Notation            =
================================*/

.ui-stars-star {
	float: left;
	display : block;
	width: 20px;
	height: 20px;
	font-size: 0;
	background: url("/images/star-off.png") no-repeat -2px 0;
}
.ui-stars-star:nth-child(even) { background-position: -10px 0; }
.ui-stars-star-on { background: url("/images/star-on.png") no-repeat -2px 0; }
.ui-stars-star-hover { background: url("/images/star-on.png") no-repeat -2px 0; }
.rating-stars {
	display: inline-block;
	height: 15px;
}
.nb_ratings { color: #737373; }
.rating-value { margin-left: 20px; }

/*=====  End of Notation  ======*/

/*==================================
=            Alignement            =
==================================*/
.txtLeft   { text-align: left; }
.txtCenter { text-align: center; }
.txtRight  { text-align: right; }
.posLeft   { float: left; }
.posMiddle { margin: 0 auto; }
.posRight  { float: right; }

.posMiddle { margin:0 auto 16px; text-align: center;}
.posRight  {  margin:0 auto 16px; text-align: center;}

@include media-breakpoint-up(sm) {
    .posLeft   { float: left;  margin-right: 30px;  text-align: left; }
    .posRight  { float: right;  margin-left: 30px; text-align: right}
}
/*=====  End of Alignement  ======*/


/*=========================================
=            Tailles générées             =
=========================================*/
.size1_1 { width: 100%; padding: 0 15px; }
.size1_2 {   padding: 0 15px; }
.size1_3 {  padding: 0 15px; }
.size1_4 {  padding: 0 15px; }
.size1_5 {  padding: 0 15px; }
.size1_6 {  padding: 0 15px; }
.size1_7 {  padding: 0 15px; }
.size1_8 { padding: 0 15px; }

@include media-breakpoint-up(sm) {
	.size1_1 { width: 100%; padding: 0 15px; }
    .size1_2 { width: 50%;  padding: 0 15px; }
    .size1_3 { width: 33.33%; padding: 0 15px; }
    .size1_4 { width: 25%; padding: 0 15px; }
    .size1_5 { width: 20%; padding: 0 15px; }
    .size1_6 { width: 16.66%; padding: 0 15px; }
    .size1_7 { width: 14.285%; padding: 0 15px; }
    .size1_8 { width: 12.5%; padding: 0 15px; }
}


/*=====  End of Tailles générées   ======*/

/*=======================================
=            Alerte saytup            =
=======================================*/
/* etoile pour champs obligatoire */
.obligatory{
	color: theme-color('danger');
}
.form .invalid-feedback{display: block;}
/* Message "Champ obligatoire" */
.errorForm, .error-form{
	@extend .badge;
	@extend .badge-danger;
}

/* Etat invalide des champs */
.form-group.error {
	.form-control{	@extend .is-invalid;}
}

/* Reste à tester */
.correct {
	@extend .alert;
	@extend .alert-success;
}

.errorMessage{
	@extend .alert;
	@extend .alert-danger;
}
/* validation de l'inscription au compte : Votre compte est maintenant actif. Un message comprenant votre login et votre mot de passe a été envoyé à l'adresse ...*/
.confirmation_subscription{
	.confirmation_validation{
		@extend .alert;
		@extend .alert-success;
	}
}


/*=================================
=            Diaporama            =
=================================*/
.diaporamaSimpleSlideshow {
	margin: 20px 0;
	@include media-breakpoint-down(sm) {
		width: 360px!important;
		img {
			object-fit: cover;
		}
	}
	& > div{
		& > span{
			display: none;
			width: 40px;
			height: 40px;
			text-indent: -9999px;
		}
	}
}

.diaporamaSlide { background: $white; }

.diaporamaControlPrev{
	padding: 20px;
	margin-top: -30px;
	cursor: pointer;
	line-height: 1;

	border-radius: 0 8px 8px 0;
	-moz-border-radius: 0 8px 8px 0;
	-webkit-border-radius: 0 8px 8px 0;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;

	&:hover {
		padding-left: 30px;

	}

	&:before{
		content: '\e609';
		position: relative;
		top: 1px;
		display: inline-block;
		font-size: 30px;
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		color: $gray-900;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		@extend .icomoon;
	}
}
.diaporamaControlNext {

	padding: 20px;
	margin-top: -30px;
	cursor: pointer;
	line-height: 1;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
	border-radius: 8px 0 0 8px;
	-moz-border-radius: 8px 0 0 8px;
	-webkit-border-radius: 8px 0 0 8px;

	&:hover {
		padding-right: 30px;
	}

	&:before{
		content: '\e60a';
		position: relative;
		top: 1px;
		display: inline-block;
		font-size: 30px;
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		color: $gray-900;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		@extend .icomoon;
	}
}

.diaporamaDots{
	& > div{
		position: relative;
		display: inline-block;
		padding: 10px 0;
		line-height: 9px;
		/* background: #fff; */

	}

	span{
		display: inline-block;
		width:15px;
		height: 15px;
		margin: 0 3px;
		cursor: pointer;
		text-indent: -9999px;
		background:$white;

		border-radius: 100%;
		-moz-border-radius: 100%;
		-webkit-border-radius: 100%;
		-webkit-transition: all .2s ease-out;
		-moz-transition: all .2s ease-out;
		-o-transition: all .2s ease-out;
		transition: all .2s ease-out;

		&:hover, &.diaporamaDotActive{
			background:$blue;

		}
	}
}

.diaporamaExtra{
	& > div {
		display: inline-block;
		padding: 10px;
		color: #222;
		text-align: left;
		background: rgba(255,255,255,.8);
	}
}
.diaporamaExtraMiddleLeft,
.diaporamaExtraMiddleRight,
.diaporamaExtraMiddleCenter {
	padding: 0 70px;
	transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
}
.diaporamaExtraTopLeft,
.diaporamaExtraTopRight,
.diaporamaExtraTopCenter { padding: 10px; }
.diaporamaExtraBottomLeft,
.diaporamaExtraBottomRight,
.diaporamaExtraBottomCenter { padding: 10px 10px 40px; }

.diaporamaExtraTopCenter,
.diaporamaExtraBottomCenter,
.diaporamaExtraMiddleCenter,
.diaporamaExtra.diaporamaExtraTopCenter > div,
.diaporamaExtra.diaporamaExtraBottomCenter > div,
.diaporamaExtra.diaporamaExtraMiddleCenter > div { text-align: center; }

.diaporamaExtraTopRight,
.diaporamaExtraMiddleRight,
.diaporamaExtraBottomRight { text-align: right; }
.diaporamaExtra strong { display: block; }


/*=====  End of Diaporama  ======*/

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	vertical-align: top;
}

.fancybox-wrap {
	position: absolute;
	top: 0;
	left: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	z-index: 8020;
}

.fancybox-skin {
	position: relative;
	background: #f9f9f9;
	color: #444;
	text-shadow: none;
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
	        border-radius: 4px;
}

.fancybox-opened {
	z-index: 8030;
}

.fancybox-opened .fancybox-skin {
	-webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
	   -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
	        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
	position: relative;
}

.fancybox-inner {
	overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
	-webkit-overflow-scrolling: touch;
}

.fancybox-error {
	color: #444;
	font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
	margin: 0;
	padding: 15px;
	white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
	display: block;
	width: 100%;
	height: 100%;
}

.fancybox-image {
	max-width: 100%;
	max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
	background-image: url(/images/fancybox/fancybox_sprite.png);
}

#fancybox-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -22px;
	margin-left: -22px;
	background-position: 0 -108px;
	opacity: 0.8;
	cursor: pointer;
	z-index: 8060;
}

#fancybox-loading div {
	width: 44px;
	height: 44px;
	background: url(/images/fancybox/fancybox_loading.gif) center center no-repeat;
}

.fancybox-close {
	position: absolute;
	top: -18px;
	right: -18px;
	width: 36px;
	height: 36px;
	cursor: pointer;
	z-index: 8040;
}

.fancybox-nav {
	position: absolute;
	top: 0;
	width: 40%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
	background: transparent url(/images/fancybox/blank.gif); /* helps IE */
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	z-index: 8040;
}

.fancybox-prev {
	left: 0;
}

.fancybox-next {
	right: 0;
}

.fancybox-nav span {
	position: absolute;
	top: 50%;
	width: 36px;
	height: 34px;
	margin-top: -18px;
	cursor: pointer;
	z-index: 8040;
	visibility: hidden;
}

.fancybox-prev span {
	left: 10px;
	background-position: 0 -36px;
}

.fancybox-next span {
	right: 10px;
	background-position: 0 -72px;
}

.fancybox-nav:hover span {
	visibility: visible;
}

.fancybox-tmp {
	position: absolute;
	top: -99999px;
	left: -99999px;
	max-width: 99999px;
	max-height: 99999px;
	overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: visible !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: none;
	z-index: 8010;
	background: url(/images/fancybox/fancybox_overlay.png);
}

.fancybox-overlay-fixed {
	position: fixed;
	bottom: 0;
	right: 0;
}

.fancybox-lock .fancybox-overlay {
	overflow: auto;
	overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
	visibility: hidden;
	font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
	position: relative;
	text-shadow: none;
	z-index: 8050;
}

.fancybox-opened .fancybox-title {
	visibility: visible;
}

.fancybox-title-float-wrap {
	position: absolute;
	bottom: 0;
	right: 50%;
	margin-bottom: -35px;
	z-index: 8050;
	text-align: center;
}

.fancybox-title-float-wrap .child {
	display: inline-block;
	margin-right: -100%;
	padding: 2px 20px;
	background: transparent; /* Fallback for web browsers that doesn't support RGBa */
	background: rgba(0, 0, 0, 0.8);
	-webkit-border-radius: 15px;
	   -moz-border-radius: 15px;
	        border-radius: 15px;
	text-shadow: 0 1px 2px #222;
	color: #FFF;
	font-weight: bold;
	line-height: 24px;
	white-space: nowrap;
}

.fancybox-title-outside-wrap {
	position: relative;
	margin-top: 10px;
	color: #fff;
}

.fancybox-title-inside-wrap {
	padding-top: 10px;
}

.fancybox-title-over-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #fff;
	padding: 10px;
	background: #000;
	background: rgba(0, 0, 0, .8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	   only screen and (min--moz-device-pixel-ratio: 1.5),
	   only screen and (min-device-pixel-ratio: 1.5){

	#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
		background-image: url(/images/fancybox/fancybox_sprite@2x.png);
		background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
	}

	#fancybox-loading div {
		background-image: url(/images/fancybox/fancybox_loading@2x.gif);
		background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
	}
}
