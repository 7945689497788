/*	--------------------------------------------------
	--------------------------------------------------

	Project			: xxx
	Creation date	: xx/xxx
	By				: xxx/xx

	--------------------------------------------------
	--------------------------------------------------*/
@import "_zmixins";

@import "bootstrap/_bootstrap.scss";
@import "./jquery.fancybox.min.css";


/* Modules d'E-majine */
@import "_structure";
@import "_elements";
@import "_account";
@import "_boxes";
@import "_community";
@import "_directory";
@import "_navigation";
@import "_publications";


/** Personnalisation **/
//@import "_animate";
@import "_fonts";
@import "_form";
@import "_saytup";

/* slider */
@import "_slick";



/*fancybox 3*/

