/*	--------------------------------------------------
	--------------------------------------------------
		Annuaire et ses widgets
--------------------------------------------------
--------------------------------------------------*/
.cardhebergement__box {
  padding-bottom: 15%;
  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }

  &:hover {
    .product_viewmore {
      background: #CCC;
    }
  }
}
.product_viewmore {
  background: #ECECEC;
  border-radius: 20px;
  position: absolute;
  bottom: 20px;
  right: 40%;
  padding: 0.5rem 1rem;
  transition: .3s;
}

.product_reserve {
  background: $ushuaiagold;
  color: #FFF;
  border-radius: 20px;
  position: absolute;
  bottom: 20px;
  right: 5%;
  padding: 0.5rem 1rem;
  transition: .3s;
  &:hover {
    text-decoration: none;
    color: #FFF;
    background: darken($ushuaiagold, 10);
  }
}

/* box entries (/directory/boxes/entries.html) */
// .box-dir {}
// .box-dir--list {}
// .box-dir--item {}